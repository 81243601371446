
import { Vue, Component, Prop } from 'vue-property-decorator'

import SupportNumber from '@/components/SupportNumber.vue'
import AppBarNavigationItem from '@/components/AppBarNavigationItem.vue'
import CharterUPLogo from '@/icons/CharterUPLogo.vue'
import AccountSupport from '@/components/AccountSupport.vue'

import auth from '@/store/modules/auth'
import app from '@/store/modules/app'
import quotes from '@/store/modules/quotes'
import support from '@/store/modules/support'
import { toKebab } from '@/utils/string'
import sidebar from '../store/modules/sidebar'
import { NavigationItem } from '../models/NavigationItem'
import user from '@/store/modules/user'

@Component({
  components: {
    CharterUPLogo,
    SupportNumber,
    AppBarNavigationItem,
    AccountSupport,
  },
  metaInfo() {
    return {
      title: app.title,
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      meta: [
        {
          name: 'msapplication-TileColor',
          content: '#ffc40d',
        },
        {
          name: 'theme-color',
          content: '#ffffff',
        },
        {
          name: 'apple-mobile-web-app-title',
          content: app.title,
        },
        {
          name: 'application-name',
          content: app.title,
        },
      ],
      link: [
        {
          rel: 'apple-touch-icon',
          sizes: '180x180',
          href: '/apple-touch-icon.png',
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '32x32',
          href: '/favicon-32x32.png',
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '16x16',
          href: '/favicon-16x16.png',
        },
        {
          rel: 'manifest',
          href: '/site.webmanifest',
        },
        {
          rel: 'mask-icon',
          color: '#5bbad5',
          href: '/safari-pinned-tab.svg',
        },
        {
          rel: 'favicon',
          href: '/favicon.ico',
        },
      ],
    }
  },
})
export default class TheAppBar extends Vue {
  @Prop({ type: Array, default: () => [] }) readonly menuItems: NavigationItem[]
  @Prop({ type: Boolean, default: false })
  readonly showLoggedOutSupportNumber: boolean

  auth = auth
  support = support
  quotes = quotes
  sidebar = sidebar
  toKebab = toKebab

  get userDisplayName() {
    const name = `${auth.user?.firstName || ''} ${auth.user?.lastName || ''}`

    return name.length > 1 ? name : auth.user?.email
  }

  get mainItems(): NavigationItem[] {
    return this.menuItems.filter((item) => item.location === 'main')
  }

  get profileItems(): NavigationItem[] {
    return this.menuItems.filter((item) => item.location === 'dropdown')
  }

  get showSupportNumber(): boolean {
    // If the user has a customer account support phone number, they should click the expand to get both the support number
    // and the account executive number.
    return this.auth?.customerAccount?.supportPhone === null
  }

  get showMenuItems(): boolean {
    return (
      // True when logged in and on desktop OR
      // when logged out and in specific views (e.g., LiveTracking)
      (this.$vuetify.breakpoint.mdAndUp && this.mainItems.length > 0) ||
      (!auth.user && this.showLoggedOutSupportNumber)
    )
  }

  mounted(): void {
    if (auth.isTokenSet && user.isExistingCustomer) {
      // We call this here for the initial load of the app.
      // This could just as easily happen in the router
      // which is where subsequent calls to this happen.
      quotes.fetchUnreadQuoteCount()
      auth.refreshCustomerAccount()
    }
    this.trackUserId()
  }

  trackUserId() {
    if (auth.userId) {
      this.$ga4Event('auth_load', {
        user_id: auth.userId,
      })
    }
  }
}
