
import { Vue, Component, Prop } from 'vue-property-decorator'
import { CheckoutDetailResult, CustomerAccount, Quote } from '@/models/dto'
import CheckoutQuoteSummaryCharges from '@/components/CheckoutQuoteSummaryCharges.vue'
import CheckoutQuoteSummaryDue from '@/components/CheckoutQuoteSummaryDue.vue'
import CheckoutQuoteSummaryAttentionItems from '@/components/CheckoutQuoteSummaryAttentionItems.vue'
import CheckoutQuoteSummaryTotal from '@/components/CheckoutQuoteSummaryTotal.vue'
import { RiskTypeId } from '../utils/enum'
import CustomerAccountDefaultValueSet from '@/models/dto/CustomerAccountDefaultValueSet'

@Component({
  components: {
    CheckoutQuoteSummaryCharges,
    CheckoutQuoteSummaryDue,
    CheckoutQuoteSummaryAttentionItems,
    CheckoutQuoteSummaryTotal,
  },
})
export default class CheckoutQuoteSummary extends Vue {
  @Prop({ type: Object, required: true })
  readonly quote!: Quote
  @Prop({ type: Object, required: true })
  readonly checkoutDetail!: CheckoutDetailResult
  @Prop({ type: Boolean, required: false, default: false })
  readonly fullWidth!: boolean

  get quoteId(): number {
    return this.quote.quoteId
  }

  get isDriverHotelRequired(): boolean {
    return this.quote?.trips?.some((trip) =>
      trip?.risks?.some(
        (risk) => risk?.riskTypeId === RiskTypeId.DriverHotelMissing
      )
    )
  }

  get hasAttentionItems(): boolean {
    return [this.isDriverHotelRequired].filter((val) => val).length > 0
  }
}
