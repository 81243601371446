import { Address, ReservationDetail, Trip } from '@/models/dto'
import { AmenityItem } from '@/models/AmenityItem'
import { DateTime } from 'luxon'
import { isValidISODatetime } from './datetime'
import { anyNumberPattern, stateAbbreviationPattern } from './regex'
import { TranslateResult } from 'vue-i18n'
import { areAddressCitiesTheSame, areAddressLocationsTheSame } from './address'
import { QuoteDetailV2 } from '../models/dto/QuoteDetailV2'

/**
 * The null if the input string is only whitespace or is empty.
 * @param str - The string to check for whitespace or being empty.
 * @returns null if the input string is only whitespace or is empty or the string itself otherwise.
 */
export const nullIfEmpty = (str: string): string => {
  if (!str) {
    return null
  }

  if (!str.replace(/\s/g, '').length) {
    return null
  }
  return str
}

/**
 * Returns the given noun in plural form if the count is not 1, using the provided suffix (or 's' by default).
 * @param count - The number to check for pluralization.
 * @param noun - The noun to pluralize.
 * @param suffix - The suffix to use for pluralization (defaults to 's').
 * @returns The pluralized noun.
 */
export const pluralize = (
  count: number,
  noun: string,
  suffix = 's'
): string => {
  if (noun.endsWith('s')) {
    suffix = 'es'
    return `${noun}${count !== 1 ? suffix : ''}`
  }

  return `${noun}${count !== 1 ? suffix : ''}`
}

/**
 *
 * Formats a number as a currency value with a dollar sign.
 * @param input - The number to format.
 * @returns A string representing the formatted currency value.
 */
export const currencyFilter = (input: number): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'symbol',
  })
  return `${formatter.format(input)}`
}

/**
 *
 * Rounds a number to the nearest whole number and formats it as a currency value with a dollar sign.
 * @param input - The number to round and format.
 * @returns A string representing the formatted, rounded currency value.
 */
export const roundedCurrencyFilter = (input: number): string => {
  return `${currencyFilter(Math.round(input)).split('.')[0]}`
}

/**
 * Converts a currency amount to its cent portion as a two-digit string.
 *
 * @param amount - The currency amount as a number.
 * @returns Returns the cent portion of the currency amount as a two-digit string.
 *
 * @example
 * twoDigitCentsFromCurrencyAmount(12.4); // returns "40"
 * twoDigitCentsFromCurrencyAmount(12); // returns "00"
 * twoDigitCentsFromCurrencyAmount(12.45); // returns "45"
 */
export const twoDigitCentsFromCurrencyAmount = (amount: number): string => {
  let cents = amount.toString().split('.')[1] || '0'
  cents = cents.substring(0, 2) // Truncate or take the first two digits
  return cents.padEnd(2, '0')
}

/**
 *
 * Adds commas to a number for every thousand place value.
 * @param number - The number to format with commas.
 * @returns A string representing the formatted number with commas.
 */
export const numberWithCommas = (number: number): string => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

/**
 *
 * Convert a string representing an amenity to its corresponding CUIcon name.
 * @param key - A string representing an amenity.
 * @returns The corresponding icon name for the given amenity, or 'check_circle_old' if no match is found.
 */
export const convertAmenityKeyToIconName = (key: string): string => {
  const lowercaseKey = key.toLowerCase()

  const icons = {
    wifi: 'wifi',
    luggage: 'luggage',
    lavatory: 'wc',
    seatbelts: 'seat_belt',
    ada: 'accessible',
    tv_screens: 'tv',
    outlets: 'power',
    leather_seats: 'airline_seat_recline_extra',
    spab: 'spab',
    alcohol_allowed: 'liquor',
  }

  return icons[lowercaseKey] || 'check_circle'
}

/**
 *
 * Computes the difference between a given datetime and the current time.
 * @param then - The datetime to compute the difference from.
 * @returns A string with the difference in days, hours, and minutes, or null if the input is not a valid datetime.
 */
export const expirationDelta = (then: string): string => {
  if (!then || !isValidISODatetime(then)) {
    return null
  }
  const utc = DateTime.fromISO(then).toUTC()
  const differenceInTime = utc.diffNow(['days', 'hours', 'minutes']).toObject()
  return `${differenceInTime.days}d ${differenceInTime.hours}h ${Math.round(
    differenceInTime.minutes
  )}m`
}

/**
 *
 * Formats the given number to always show the tenths place.
 * @param number - The number to be formatted.
 * @returns The formatted number as a string.
 */
export const alwaysShowTenthsPlace = (number: number): string => {
  if (!number) {
    return ''
  }
  return number?.toFixed(1)
}

/**
 * Convert a state name or abbreviation to its corresponding abbreviation or full name.
 *
 * @param input - The state name or abbreviation to convert.
 * @param to - The format to convert the input to. Must be "abbr" to convert the input to an abbreviation,
 *             or "name" to convert the input to a full name.
 * @returns The input converted to the specified format, or `null` if the input is invalid.
 */
export function abbrState(input: string, to: string): string {
  if (!input) {
    return null
  }

  const states = [
    ['Arizona', 'AZ'],
    ['Alabama', 'AL'],
    ['Alaska', 'AK'],
    ['Arkansas', 'AR'],
    ['California', 'CA'],
    ['Colorado', 'CO'],
    ['Connecticut', 'CT'],
    ['Delaware', 'DE'],
    ['Florida', 'FL'],
    ['Georgia', 'GA'],
    ['Hawaii', 'HI'],
    ['Idaho', 'ID'],
    ['Illinois', 'IL'],
    ['Indiana', 'IN'],
    ['Iowa', 'IA'],
    ['Kansas', 'KS'],
    ['Kentucky', 'KY'],
    ['Louisiana', 'LA'],
    ['Maine', 'ME'],
    ['Maryland', 'MD'],
    ['Massachusetts', 'MA'],
    ['Michigan', 'MI'],
    ['Minnesota', 'MN'],
    ['Mississippi', 'MS'],
    ['Missouri', 'MO'],
    ['Montana', 'MT'],
    ['Nebraska', 'NE'],
    ['Nevada', 'NV'],
    ['New Hampshire', 'NH'],
    ['New Jersey', 'NJ'],
    ['New Mexico', 'NM'],
    ['New York', 'NY'],
    ['North Carolina', 'NC'],
    ['North Dakota', 'ND'],
    ['Ohio', 'OH'],
    ['Oklahoma', 'OK'],
    ['Oregon', 'OR'],
    ['Pennsylvania', 'PA'],
    ['Rhode Island', 'RI'],
    ['South Carolina', 'SC'],
    ['South Dakota', 'SD'],
    ['Tennessee', 'TN'],
    ['Texas', 'TX'],
    ['Utah', 'UT'],
    ['Vermont', 'VT'],
    ['Virginia', 'VA'],
    ['Washington', 'WA'],
    ['West Virginia', 'WV'],
    ['Wisconsin', 'WI'],
    ['Wyoming', 'WY'],
  ]

  if (to === 'abbr') {
    input = input.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
    for (let i = 0; i < states.length; i++) {
      if (states[i][0] === input) {
        return states[i][1]
      }
    }
  } else if (to === 'name') {
    input = input.toUpperCase()
    for (let i = 0; i < states.length; i++) {
      if (states[i][1] === input) {
        return states[i][0]
      }
    }
  }
  return null
}

/**
 *
 * Formats the pickup and dropoff locations for a quote into a string.
 * @param quote - The quote to get the pickup and dropoff locations from.
 * @param dividerText - The text to use as a divider between the pickup and dropoff locations.
 * @returns A string with the formatted pickup and dropoff locations.
 */
export const formatQuotePickupDestinationText = (
  quote: QuoteDetailV2,
  dividerText: TranslateResult = 'to'
): string => {
  const cities = getQuotePickupDestination(quote)
  if (!cities.pickup && !cities.dropoff) {
    return null
  }
  return `${cities.pickup} ${dividerText} ${cities.dropoff}`
}

/**
 *
 * Formats the pickup and destination cities as a string with a specified divider.
 * @param address1 - The pickup address
 * @param address2 - The destination address
 * @param dividerText - The string to use as a divider between the pickup and destination cities (default: 'to')
 * @returns A string in the format 'pickup city dividerText destination city'
 */
export const formatPickupDestinationTextFromAddresses = (
  address1: Address,
  address2: Address,
  dividerText = 'to'
): string => {
  const cities = getPickupDestinationCitiesFromAddresses(address1, address2)
  return `${cities.pickup} ${dividerText} ${cities.dropoff}`
}

/**
 * Returns the pickup and dropoff locations for the given quote as strings.
 *
 * @param quote - The quote to get pickup and dropoff locations for.
 * @returns An object with two properties: pickup and dropoff, each representing the pickup and dropoff locations for the quote.
 */
export const getQuotePickupDestination = (
  quote: QuoteDetailV2
): { pickup: string; dropoff: string } => {
  const trip = quote?.trips?.[0]
  return getPickupDestinationCitiesFromAddresses(
    trip?.stops?.[0]?.address,
    trip?.stops?.[1]?.address
  )
}

/**
 *
 * Returns a formatted pickup and dropoff destination string from a given reservation object.
 * @param reservation - The reservation object to extract pickup and dropoff destination from.
 * @param dividerText - The text to use as a divider between the pickup and dropoff destinations.
 * @returns A formatted pickup and dropoff destination string.
 */
export const formatReservationPickupDestinationText = (
  reservation: ReservationDetail,
  dividerText: TranslateResult = 'to'
): string => {
  const firstStop = reservation?.stops?.[0]
  const secondStop = reservation?.stops?.[1]
  if (!firstStop && !secondStop) {
    return ''
  }
  const cities = getPickupDestinationCitiesFromAddresses(
    firstStop?.address,
    secondStop?.address
  )
  return `${cities.pickup} ${dividerText} ${cities.dropoff}`
}

/**
 * Extracts the pickup and dropoff cities from the given addresses.
 *
 * @param address1 - The first address object.
 * @param address2 - The second address object.
 * @returns An object with the `pickup` and `dropoff` cities.
 */
export const getPickupDestinationCitiesFromAddresses = (
  address1: Address,
  address2: Address
): { pickup: string; dropoff: string } => {
  if (!address1 && !address2) {
    return { pickup: null, dropoff: null }
  }
  let pickup =
    address1?.city ||
    cityFromAddressName(address1?.addressName) ||
    abbrState(address1?.state, 'name')

  const dropoff =
    address2?.city ||
    cityFromAddressName(address2?.addressName) ||
    abbrState(address2?.state, 'name') ||
    pickup

  if (!pickup && !!dropoff) {
    pickup = dropoff
  }

  return { pickup, dropoff }
}

/**
 * Returns the city from an address name.
 *
 * @param addressName - The address name to get the city from.
 * @returns The city from the address name.
 */
export const cityFromAddressName = (addressName: string): string => {
  if (!addressName) {
    return null
  }
  const addressNameSplit = addressName
    .replace(anyNumberPattern, '')
    .split(',')
    .map((str) => str.trim())

  const stateIndex = addressNameSplit.findIndex((string) =>
    stateAbbreviationPattern.test(string)
  )
  return addressNameSplit[stateIndex - 1] || null
}

/**
 * Converts a snake case string to pascal case.
 *
 * @param string - The snake case string to convert to pascal case.
 * @returns The pascal case string.
 */
export const snakeToPascal = (string: string): string => {
  return string
    .replace(/ /g, '')
    .toLowerCase()
    .split('_')
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1)
    })
    .join('')
}

/**
 *
 * Truncate a string to the given number of characters.
 * @param str - The string to truncate.
 * @param n - The number of characters to truncate to.
 * @param useWordBoundary - Whether to truncate at a word boundary.
 * @returns The truncated string.
 */
export const truncate = (str: string, n: number, useWordBoundary = false) => {
  if (str.length <= n || !Number.isInteger(n) || n <= 0) {
    return str
  }
  const subString = str.substr(0, n - 1)
  return `${useWordBoundary
    ? `${subString.substr(0, subString.lastIndexOf(' '))} `
    : subString
    }...`
}

/**
 *
 * Converts a string to kebab case.
 * @param string - The string to convert.
 * @returns The string in kebab case.
 */
export const toKebab = (string: string): string => {
  if (!string) {
    return null
  }
  return string
    .split('')
    .map((letter) => {
      if (/[A-Z]/.test(letter)) {
        return ` ${letter.toLowerCase()}`
      }
      return letter
    })
    .join('')
    .trim()
    .replace(/[_\s]+/g, '-')
}

/**
 *
 * Converts a string to camel case.
 * @param string - The string to convert to camel case.
 * @returns The string in camel case.
 */
export const toCamel = (string: string): string => {
  if (!string) {
    return null
  }
  return toKebab(string)
    .split('-')
    .map((word, index) => {
      if (index === 0) {
        return word
      }
      return word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase()
    })
    .join('')
}

/**
 * Converts a string to a pascal case string.
 *
 * @param string - The string to convert.
 * @returns The pascal case string.
 *
 * @example toPascal('hello world') // 'HelloWorld'
 */
export const toPascal = (string: string): string => {
  if (!string) {
    return null
  }
  const interim = toCamel(string)
  return interim.slice(0, 1).toUpperCase() + interim.slice(1)
}

/**
 * Converts a string to a title case string.
 *
 * @param string - The string to convert.
 * @returns The title case string.
 */
export const toTitle = (string: string): string => {
  if (!string) {
    return null
  }
  return toKebab(string)
    .split('-')
    .map((word) => {
      return word.slice(0, 1).toUpperCase() + word.slice(1)
    })
    .join(' ')
}

/**
 *
 * Converts a string to sentence case.
 * @param string - The string to convert.
 * @returns The sentence-cased string.
 */
export const toSentence = (string: string): string => {
  if (!string) {
    return null
  }
  const interim = toKebab(string).replace(/-/g, ' ')
  return interim.slice(0, 1).toUpperCase() + interim.slice(1)
}

/**
 * Converts a string to snake case.
 *
 * @param string - The string to convert to snake case.
 * @returns The string in snake case.
 */
export const toSnake = (string: string): string => {
  if (!string) {
    return null
  }
  return toKebab(string).replace(/-/g, '_')
}

/**
 *
 * Formats an address into a pretty string that includes the street1, city, and state.
 * @param street - The string that contains the street information.
 * @param city - The string that contains the city information.
 * @param state - The string that contains the state information.
 * @returns A string with the street1, city, and state of the address.
 */
export const addressPretty = (street: string, city: string, state: string) => {
  const parts = [street?.trim(), city?.trim(), state?.trim()]
    .filter(Boolean) // Remove any falsy values (e.g., null, undefined, empty strings)
  return parts.join(', ')
}

/**
 *
 * Formats an address into a pretty string that includes the street1, city, and state.
 * @param stop - The stop object that contains the address information.
 * @returns A string with the street1, city, and state of the address.
 */
export const addressPrettyFromStop = (stop) => {
  const street1 = stop?.address?.street1
  const city = stop?.address?.city
  const state = stop?.address?.state
  return addressPretty(street1, city, state)
}

/**
 * Returns the address information of a stop.
 *
 * @param stop - The stop object to extract address information from.
 * @returns The address information of the stop.
 */
// TODO: add a type to the stop - later
export const formattedStopName = (stop): string => {
  if (!stop) {
    return ''
  }
  const address = stop.address
  if (!address) {
    return ''
  }
  return address.title || address.street1 || address.city || address.state || ''
}

/**
 *
 * Formats the provided time string in the provided time zone to display as a human-readable string.
 * @param time - The time string to be formatted.
 * @param timeZone - The time zone in which the provided time should be formatted.
 * @returns A human-readable string representing the provided time in the provided time zone.
 */
export const formatDisplayTime = (time: string, timeZone: string, format: string = 'h:mm a'): string => {
  if (!time || !timeZone) {
    return ''
  }
  return DateTime.fromISO(time, { zone: timeZone }).toFormat(format)
}

/**
 *
 * Formats and filters for included amenities to display as a human-readable string.
 * @param amenities - The amenities to be formatted.
 * @returns A human-readable string representing the included amenities on a charter bus.
 */
export const formatIncludedAmenities = (amenities: AmenityItem[]): string => {
  if (!amenities || !amenities.length) {
    return ''
  }
  const includedAmenitiesString = amenities
    .filter((amenity) => !!amenity.included)
    .map((amenity) =>
      amenity.title
        .split(' ')
        .map((word) =>
          word === word.toUpperCase() ? word : word.toLowerCase()
        )
        .join(' ')
    )
    .join(', ')
    .replace(/,([^,]*)$/, ' and$1')
    .replace(/^./, (firstChar) => firstChar.toUpperCase())

  if (!includedAmenitiesString) {
    return ''
  }

  return `${includedAmenitiesString} are available on most Charter Buses.`
}

/**
 *
 * Check if the given string matches the SHA-256 hash format.
 * @param str - The string to check.
 * @returns true if the string matches the SHA-256 hash format, false otherwise.
 */
export const checkIfValidSHA256 = (str: string): boolean => {
  const regexExp = /^[a-f0-9]{64}$/gi

  return regexExp.test(str)
}

/**
 * Returns the initials of a given first and last name.
 *
 * @param firstName - The first name.
 * @param lastName - The last name.
 * @returns The initials of the given first and last name.
 */
export const getInitials = (firstName: string, lastName: string): string => {
  let initials = ''
  if (firstName) {
    initials = firstName[0]
  }
  if (lastName) {
    initials = `${initials}${lastName[0]}`
  }
  return initials
}

/**
 * Validates an email address by checking if it matches the pattern of a typical email address.
 * @param {string} email the email address to be validated.
 * @returns {boolean} a `boolean` indicating whether the email address is valid.
 */
export const validateEmailAddress = (email: string): boolean => {
  if (!email) {
    return false
  }
  // eslint-disable-next-line no-useless-escape
  return /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,13}$/.test(email)
}

/**
 * This function returns the linking verb for a given quantity and tense.
 * @param singular - whether the quantity is singular or plural.
 * @param tense - The tense to check.
 * @returns The linking verb for the given quantity and tense.
 */
export const getLinkingVerb = (
  singular: boolean,
  tense: 'present' | 'past'
): string => {
  if (tense === 'present') {
    return singular ? 'is' : 'are'
  }
  if (tense === 'past') {
    return singular ? 'was' : 'were'
  }
  return ''
}

/**
 * This function returns the human readable description of a trip's itinerary from a trip
 * @param quote - The quote detail object to get the itinerary description from.
 * @returns The human readable description of the trip's itinerary.
 */
export const getItineraryDescription = (trip: Trip): string => {
  // sort stops by order index
  const stops = trip.stops.sort((a, b) => a.orderIndex - b.orderIndex)
  const numberOfStops = stops.length

  const pickup = stops[0]
  const dropoff = stops[stops.length - 1]

  const pickupAddress = pickup.address
  const dropoffAddress = dropoff.address

  const dropoffCity = dropoffAddress?.city

  const areLocationsTheSame = areAddressLocationsTheSame([
    pickupAddress,
    dropoffAddress,
  ])
  const areCitiesTheSame = areAddressCitiesTheSame([
    pickupAddress,
    dropoffAddress,
  ])

  if (numberOfStops === 2) {
    const dropoffLocationName =
      dropoffAddress?.title || dropoffCity || dropoffAddress?.state
    return `Trip to ${dropoffLocationName}`
  }

  const intermediateStops = stops.slice(1, stops.length - 1)
  const intermediateAddresses = intermediateStops.map(({ address }) => address)
  const areIntermediateStopCitiesTheSame = areAddressCitiesTheSame(
    intermediateAddresses
  )

  const secondStopAddress = stops[1].address
  const secondStopLocationName =
    secondStopAddress?.title ||
    secondStopAddress.city ||
    secondStopAddress?.state

  if (numberOfStops >= 4) {
    if (areCitiesTheSame && areIntermediateStopCitiesTheSame) {
      return `Trip around ${dropoffCity}`
    }
    if (areCitiesTheSame && !areIntermediateStopCitiesTheSame) {
      return `Trip to ${secondStopLocationName} & ${intermediateStops.length} more`
    }
  }

  const areIntermediateStopCitiesTheSameAsPickup = areAddressCitiesTheSame([
    pickupAddress,
    ...intermediateAddresses,
  ])
  const areIntermediateStopCitiesTheSameAsDropoff = areAddressCitiesTheSame([
    dropoffAddress,
    ...intermediateAddresses,
  ])
  const areIntermediateStopCitiesTheSameAsPickupAndDropoff =
    areIntermediateStopCitiesTheSameAsPickup &&
    areIntermediateStopCitiesTheSameAsDropoff

  if (numberOfStops >= 3) {
    if (
      !areLocationsTheSame &&
      !areCitiesTheSame &&
      !areIntermediateStopCitiesTheSameAsPickupAndDropoff
    ) {
      return `Trip to ${dropoffCity}`
    }
  }

  if (numberOfStops === 3) {
    return `Trip to ${secondStopLocationName} and back`
  }

  return ''
}
