import { AxiosResponse } from 'axios'
import { baseUrl } from '@/utils/env'
import { HttpService } from './common/HttpService'
import {
  ApiResult,
  CancellationConfirmation,
  GenericApiResult,
  Reservation,
  SharedListResult,
  SimpleReservationResponse,
  TableViewParameters,
  TableViewReservation,
  TableViewResult,
} from '@/models/dto'
import { ReservationDetail } from '../models/dto/ReservationDetail'
import { TrackingReservation } from '../models/dto/TrackingReservation'
import ReservationCancellationPayload from '../models/dto/ReservationCancellationPayload'
import { MfaSmsPayload } from '@/models/dto/MfaSms'

const httpService: HttpService = new HttpService()
export default {
  /**
   * Gets a table view of reservations based on the given parameters.
   *
   * @param params - The parameters to use for filtering, sorting, and pagination of the table view.
   * @returns A promise that resolves to the table view result containing a count and list of reservations.
   */
  tableView(
    params: TableViewParameters
  ): Promise<AxiosResponse<TableViewResult<TableViewReservation>>> {
    const { filters = null, sorts = null, pageSize = 10, page = 1 } = params
    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query = `${query}&${sorts}`
    }
    if (filters) {
      query = `${query}&${filters}`
    }
    query = encodeURI(query)
    const host = baseUrl()
    const url = `https://${host}/tables/referrals?${query}`
    return httpService.get(url)
  },
  /**
   * Retrieves a reservation by its ID.
   * @param reservationId - The ID of the reservation to be retrieved.
   * @returns A promise that resolves to the reservation.
   */
  byId(
    reservationId: number
  ): Promise<AxiosResponse<GenericApiResult<ReservationDetail>>> {
    const url = `https://${baseUrl()}/reservations/customer/${reservationId}`
    return httpService.get(url)
  },
  /**
   * Retrieves a reservation by its ID.
   * @param reservationId - The ID of the reservation to be retrieved.
   * @returns A promise that resolves to the reservation.
   */
  byIdOld(reservationId: number): Promise<AxiosResponse<Reservation>> {
    const url = `https://${baseUrl()}/reservations/v2/${reservationId}`
    return httpService.get(url)
  },
  /**
   * Retrieves a reservation by its hash.
   * @param reservationHash - The hash of the reservation to be retrieved.
   * @returns A promise that resolves to the reservation.
   */
  byHash(reservationHash: string): Promise<AxiosResponse<GenericApiResult<ReservationDetail>>> {
    const url = `https://${baseUrl()}/reservations/customer/hash/${reservationHash}`
    return httpService.get(url)
  },
  /**
   * Retrieves a reservation by its hash - DEPRACTED.
   * @param reservationHash - The hash of the reservation to be retrieved.
   * @returns A promise that resolves to the reservation.
   */
  byHashOld(reservationHash: string): Promise<AxiosResponse<Reservation>> {
    const url = `https://${baseUrl()}/reservations/hash/${reservationHash}`
    return httpService.get(url)
  },
  /**
   * Retrieves a reservation by its hash with no authentication
   * @param reservationHash - The hash of the reservation to be retrieved.
   * @returns A promise that resolves to the reservation.
   */
  byHashSimple(
    reservationHash: string
  ): Promise<AxiosResponse<SimpleReservationResponse>> {
    const url = `https://${baseUrl()}/reservations/simple/${reservationHash}`
    return httpService.get(url)
  },
  /**
   * Retrieves the list of users that the reservation with the given ID is shared with.
   * @param reservationId - The ID of the reservation to retrieve the shared list for.
   * @returns A promise that resolves to the list of users that the reservation with the given ID is shared with.
   */
  sharedList(reservationId: number): Promise<AxiosResponse<SharedListResult>> {
    const url = `https://${baseUrl()}/reservations/${reservationId}/sharedList`
    return httpService.get(url)
  },
  /**
   * Shares the reservation with the given ID with the user with the given user ID.
   *
   * @param reservationId - The ID of the reservation to share.
   * @param userId - The ID of the user to share the reservation with.
   * @returns A promise that resolves to a standard API result.
   */
  share(
    reservationId: number,
    userId: number
  ): Promise<AxiosResponse<ApiResult>> {
    const url = `https://${baseUrl()}/reservations/${reservationId}/share/${userId}`
    return httpService.post(url, null)
  },
  /**
   * Unshares the reservation with the given ID with the user with the given user ID.
   *
   * @param reservationId - The ID of the reservation to unshare.
   * @param userId - The ID of the user to unshare the reservation with.
   * @returns A promise that resolves to a standard API result.
   */
  unshare(
    reservationId: number,
    userId: number
  ): Promise<AxiosResponse<ApiResult>> {
    const url = `https://${baseUrl()}/reservations/${reservationId}/unshare/${userId}`
    return httpService.post(url, null)
  },
  confirmCancellation(
    reservationId: number,
    payload: CancellationConfirmation
  ): Promise<AxiosResponse<ApiResult>> {
    const url = `https://${baseUrl()}/reservations/${reservationId}/confirmCancellation`
    return httpService.post(url, payload)
  },
  async trackingByHash(
    hash: string
  ): Promise<AxiosResponse<GenericApiResult<TrackingReservation>>> {
    const url = `https://${baseUrl()}/reservations/customer/${hash}/liveTracking`
    return httpService.get(url)
  },
  cancel(payload: ReservationCancellationPayload): Promise<AxiosResponse<ApiResult>> {
    const url = `https://${baseUrl()}/clientCancellation/selfServe`
    return httpService.post<ApiResult, ReservationCancellationPayload>(url, payload)
  },
  /**
   * Verifies the SMS information and adds the user as a reservation watcher if valid.
   * @param payload - The payload containing the phone number and OTP.
   * @returns A promise that resolves to the API result.
   */
  addReservationWatcher(
    hash: string,
    payload: {
      phoneNumber: string,
      smsOtp: string
    }
  ): Promise<AxiosResponse<ApiResult>> {
    const url = `https://${baseUrl()}/reservations/${hash}/watchers`
    return httpService.post<ApiResult, MfaSmsPayload>(url, payload)
  },
}
