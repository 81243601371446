
import { Vue, Component, Prop } from 'vue-property-decorator'
import { OperatorDetailCompany } from '../models/dto'
import CertifiedPartnerBadge from './CertifiedPartnerBadge.vue'
import CharterUPRating from '@/components/CharterUPRating.vue'
import ResellerTooltip from '@/components/ResellerTooltip.vue'
import { certifiedPartnerModalDetail } from '@/utils/infoModals'
import InfoModal from '@/components/InfoModal.vue'
import { PartnerTypeId } from '@/utils/enum'

@Component({
  components: { CertifiedPartnerBadge, InfoModal, CharterUPRating, ResellerTooltip }
})
export default class ProviderInfoHeader extends Vue {
  @Prop({ type: Object, required: true })
  readonly company!: OperatorDetailCompany

  certifiedPartnerModalDetail = certifiedPartnerModalDetail
  isOpen = false

  get website(): string {
    return this.company.customerFacingUrl
  }

  get isReseller(): boolean {
    return [PartnerTypeId.GoldReseller,
            PartnerTypeId.SilverReseller,
            PartnerTypeId.BronzeReseller].includes(this.company.partnerTypeId)
  }

  getUrl(url): string {
    if (!url.match(/^https?:\/\//i)) {
      url = `http://${url}`
    }
    return url
  }
}
