/* eslint-disable no-redeclare */
import Vue from 'vue'
import Router, {
  isNavigationFailure,
  NavigationFailureType,
  RawLocation,
  Route,
} from 'vue-router'
import VueMeta from 'vue-meta'
import axios from 'axios'

import routes from './routes'
import modules from '@/store/modules'
import systemParameter from '@/store/modules/systemParameter'

import { handleDelegatedJWTLogin, redirectToQuoteTableIfNeeded, handleToEntityDetailByHash, doesRouteRequireAuth } from '@/utils/router'
import { isForbiddenStatus, isUnauthorizedStatus } from "@/utils/axios";
import {StatusCodes} from "http-status-codes";

Vue.use(Router)
Vue.use(VueMeta)

const originalPush = Router.prototype.push
Router.prototype.push = function push(location: RawLocation): Promise<Route> {
  return new Promise((resolve, reject) => {
    const onComplete = () => resolve(this.currentRoute)
    const onAbort = (error) => {
      const isDuplicate = isNavigationFailure(
        error,
        NavigationFailureType.duplicated
      )
      const isRedirected = isNavigationFailure(
        error,
        NavigationFailureType.redirected
      )
      const isCancelled = isNavigationFailure(
        error,
        NavigationFailureType.cancelled
      )
      if (isDuplicate || isRedirected || isCancelled) {
        console.warn(error)
        resolve(this.currentRoute)
      } else {
        reject(error)
      }
    }
    originalPush.call(this, location, onComplete, onAbort)
  })
}

const router = new Router({
  routes,
  mode: 'history',
  base: process.env.BASE_URL,
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    return { x: 0, y: 0 }
  },
})

router.beforeEach(async (to, from, next) => {
  if (to.name === 'auth-callback') { // skip everything if we are trying to go to the auth-callback route
    return next()
  }

  const doesToRouteRequireAuth = doesRouteRequireAuth(to)

  if (!doesToRouteRequireAuth) {
    return next()
  }

  // Redirect to quote table view with modal if specific query params are present
  if (redirectToQuoteTableIfNeeded(to, next)) {
    return
  }

  await handleDelegatedJWTLogin(to) // this is for delegated JWT login

  const isAuthenticated = modules.auth.isTokenSet
  if (isAuthenticated) {
    // check if we have complete required user information
    // if we don't we will request it from the user
    if (!modules.user.isRequiredInformationComplete) {
      await modules.user.fetchRequiredInformation()
    }

    // now that we refreshed the user information, check if we have everything we need
    // if so, we can continue with the authorized requests
    if (modules.user.isRequiredInformationComplete){
      const isUserIdMissing = !modules.auth?.userId
      if (isUserIdMissing && modules.auth?.user) {
        modules.auth.setUserIdFromUser()
      }

      try {
        await Promise.all([
          systemParameter.fetchSystemParameters(),
          modules.auth.fetchUserDetails(),
          modules.auth.refreshCustomer(),
          modules.auth.refreshRolesAndPermissions()
        ])

        await modules.quotes.fetchUnreadQuoteCount()
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (isUnauthorizedStatus(error) || isForbiddenStatus(error)) {
            await modules.auth.logout()
          }
        }
      }
    }
  }

  const isRedirectedToEntityDetailByHash = await handleToEntityDetailByHash(isAuthenticated, to, next)
  if (isRedirectedToEntityDetailByHash) {
    return
  }

  if (doesToRouteRequireAuth && !isAuthenticated) {
    return next({
      name: 'login',
      query: { redirectFrom: to.fullPath }
    })
  }

  return next()
})

export default router
