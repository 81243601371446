var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex justify-center"},[_c('v-form',{ref:"required-customer-info-form",staticClass:"w-full"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('label',[_vm._v("First Name")]),_c('v-text-field',{ref:"first-name",attrs:{"id":"required-customer-information-first-name-text-field","value":_vm.user.firstName,"placeholder":"e.g. Joseph","rules":[(v) => !!v || 'First name is required'],"hide-details":"auto","autocomplete":"given-name","required":"","validate-on-blur":"","outlined":""},on:{"input":function($event){return _vm.user.setFirstName($event)}}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('label',[_vm._v("Last Name")]),_c('v-text-field',{attrs:{"id":"required-customer-information-last-name-text-field","value":_vm.user.lastName,"placeholder":"e.g. Smith","rules":[(v) => !!v || 'Last name is required'],"hide-details":"auto","autocomplete":"family-name","required":"","validate-on-blur":"","outlined":""},on:{"input":function($event){return _vm.user.setLastName($event)}}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('label',[_vm._v("Phone Number")]),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(['(###) ###-####', '+## ## #### ####']),expression:"['(###) ###-####', '+## ## #### ####']"}],attrs:{"id":"required-customer-information-phone-text-field","value":_vm.user.phone,"placeholder":"e.g. (123) 456-7890","rules":[
            (v) => !!v || 'Phone number is required',
            _vm.phoneRaw.length > 9 || 'Phone number must be valid',
            _vm.phoneRaw.length <= 12 || 'Phone number must be valid',
          ],"hide-details":"auto","autocomplete":"tel","required":"","validate-on-blur":"","outlined":""},on:{"input":function($event){return _vm.user.setPhone($event)}}}),_c('div',{staticClass:"d-flex justify-start margin-l-1"},[_c('span',{staticClass:"font-12 text-gray-subtle-text"},[_vm._v(" In case we need to contact you about your bookings. ")])])],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('label',[_vm._v(" How many buses do you book in a year? ")]),_c('v-text-field',{attrs:{"id":"required-customer-information-bookings-text-field","value":_vm.user.bookings,"type":"number","placeholder":"e.g. 1","hide-details":"auto","rules":[
            (v) =>
              !v || Number.isInteger(Number(v)) || 'Must be a whole number',
            (v) => !v || parseInt(v) > 0 || 'Must be a positive number',
          ],"outlined":"","step":"1","min":"1","max":"999999"},on:{"input":function($event){return _vm.user.setBookings($event)}}}),_c('div',{staticClass:"d-flex justify-start margin-l-1"},[_c('span',{staticClass:"font-12 text-gray-subtle-text"},[_vm._v("Optional")])])],1),(_vm.showReservationSMSOptIn)?_c('v-col',{attrs:{"cols":"12"}},[_c('ReservationUpdateSMSOptIn',{attrs:{"model-value":_vm.isReservationSMSEnabled},on:{"update:modelValue":function($event){_vm.isReservationSMSEnabled = $event}}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('QuoteSalesSMSOptIn',{attrs:{"value":_vm.user.isQuoteFollowUpSMSEnabled},on:{"update:value":_vm.user.setIsQuoteFollowUpSMSEnabled}})],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.errorMessage)?_c('p',{staticClass:"text-center text-error font-medium"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e(),_c('v-btn',{staticClass:"w-full margin-t-3",attrs:{"id":"required-customer-information-submit-button","color":"primary","loading":_vm.isSubmitting},on:{"click":_vm.submit}},[_vm._v(" Continue ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }