import { Axios, AxiosResponse } from 'axios'
import { baseUrl } from '@/utils/env'
import { HttpService } from './common/HttpService'
import {
  ApiResult,
  CreateCustomerPayload,
  Customer,
  CustomerCheckSignedUpResult,
  CustomerResult,
  EditCustomerAccountPayload,
  EditCustomerPayload,
  GenericApiResult,
  InviteCustomerPayload,
  TableViewCustomer,
  TableViewParameters,
  TableViewResult,
} from '@/models/dto'
import { MfaSmsPayload } from '@/models/dto/MfaSms'
import {
  CustomerNotificationSetting,
  CustomerNotificationSettingUpdatePayload,
} from '@/models/dto/CustomerNotificationSetting'
import RequiredCustomerInfoDTO from '@/models/dto/RequiredCustomerInfoDTO'
import RequiredCustomerInfoUpdateDTO from '@/models/dto/RequiredCustomerInfoUpdateDTO'
import SelfServeCreatedCustomerDTO from '@/models/dto/SelfServeCreatedCustomerDTO'
import RequiredCustomerInfoCreateDTO from '@/models/dto/RequiredCustomerInformationCreateDTO'
const httpService: HttpService = new HttpService()
const host = baseUrl()

export default {
  /**
   * Retrieves a page of customers according to the given table view parameters.
   *
   * @param params - The parameters to use for filtering, sorting, and pagination of the table view.
   * @returns A promise that resolves to the page of customers.
   */
  tableView(
    params: TableViewParameters
  ): Promise<AxiosResponse<TableViewResult<TableViewCustomer>>> {
    const { filters = null, sorts = null, pageSize = 10, page = 1 } = params
    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query = `${query}&${sorts}`
    }
    if (filters) {
      query = `${query}&${filters}`
    }
    query = encodeURI(query)
    const url = `https://${host}/tables/customers?${query}`
    return httpService.get(url)
  },
  /**
   * Retrieves information about the customer with the given ID.
   *
   * @param id - The ID of the customer to be retrieved.
   * @returns A promise that resolves to the customer's information.
   */
  byId(id: number): Promise<AxiosResponse<CustomerResult>> {
    const url = `https://${baseUrl()}/customers/${id}`
    return httpService.get(url)
  },
  /**
   * Updates the information for the customer with the given ID.
   *
   * @param payload - The updated customer information.
   * @returns A promise that resolves to the result of the update.
   */
  edit(
    payload: Customer | Partial<Customer>
  ): Promise<AxiosResponse<CustomerResult>> {
    const url = `https://${host}/customers/${payload.customerId}`
    return httpService.patch<CustomerResult, Customer | Partial<Customer>>(
      url,
      payload
    )
  },
  /**
   * Creates a new customer.
   *
   * @param payload - The payload containing the customer's information.
   * @returns A promise that resolves to the result of the creation.
   */
  create(
    payload: CreateCustomerPayload
  ): Promise<AxiosResponse<CustomerResult>> {
    const url = `https://${host}/customers`
    return httpService.post<CustomerResult, CreateCustomerPayload>(url, payload)
  },
  selfServeCreate(payload: RequiredCustomerInfoCreateDTO): Promise<AxiosResponse<SelfServeCreatedCustomerDTO>> {
    const url = `https://${host}/customers/self-serve`
    return httpService.post<SelfServeCreatedCustomerDTO, RequiredCustomerInfoCreateDTO>(url, payload)
  },
  /**
   * Invites a customer to create an account, using a reservation ID as reference.
   *
   * @param reservationId - The ID of the reservation that the customer will be invited for.
   * @param payload - The payload containing the customer's information.
   * @param tripContact - A boolean indicating whether the customer is the trip contact.
   *  (A different email is sent depending on the customer)
   *
   * @returns A promise that resolves to the result of the invitation.
   */
  inviteByReservationId(
    reservationId: number,
    payload: InviteCustomerPayload,
    tripContact: boolean
  ): Promise<AxiosResponse<CustomerResult>> {
    const url = `https://${host}/customers/invite/reservation/${reservationId}?tripContact=${tripContact}`
    return httpService.post<CustomerResult, InviteCustomerPayload>(url, payload)
  },
  /**
   * Invites a customer to create an account, using a quote ID as reference.
   *
   * @param quoteId - The ID of the quote that the customer will be invited for.
   * @param payload - The payload containing the customer's information.
   * @returns A promise that resolves to the result of the invitation.
   */
  inviteByQuoteId(
    quoteId: number,
    payload: InviteCustomerPayload
  ): Promise<AxiosResponse<CustomerResult>> {
    const url = `https://${host}/customers/invite/quote/${quoteId}`
    return httpService.post<CustomerResult, InviteCustomerPayload>(url, payload)
  },
  /**
   * Checks if a customer with the given email is already signed up.
   *
   * @param email - The email of the customer to be checked.
   * @returns A promise that resolves to the result of the check.
   */
  isSignedUpByEmail(
    email: string
  ): Promise<AxiosResponse<CustomerCheckSignedUpResult>> {
    const url = `https://${host}/customers/checkSignedUp/${email}`
    return httpService.get(url)
  },
  /**
   * Updates the SMS information for a customer.
   * @param customerId - The ID of the customer.
   * @param payload - The payload containing the updated SMS information.
   * @returns A promise that resolves to the API result.
   */
  updateSMS(
    customerId: number,
    payload: MfaSmsPayload
  ): Promise<AxiosResponse<ApiResult>> {
    const url = `https://${host}/customers/${customerId}/updateSms`
    return httpService.patch<ApiResult, MfaSmsPayload>(url, payload)
  },
  /**
   * Verifies the SMS information for a customer.
   * @param customerId - The ID of the customer.
   * @param payload - The payload containing the SMS verification information.
   * @returns A promise that resolves to the API result.
   */
  verifySMS(
    customerId: number,
    payload: MfaSmsPayload
  ): Promise<AxiosResponse<ApiResult>> {
    const url = `https://${host}/customers/${customerId}/verifySms`
    return httpService.patch<ApiResult, MfaSmsPayload>(url, payload)
  },
  getNotificationSettings(): Promise<AxiosResponse<GenericApiResult<CustomerNotificationSetting[]>>> {
    const url = `https://${host}/customers/notificationSettings`
    return httpService.get<
      GenericApiResult<CustomerNotificationSetting[]>,
      void
    >(url)
  },
  updateNotificationSettings(
    payload: CustomerNotificationSettingUpdatePayload
  ): Promise<AxiosResponse<GenericApiResult<CustomerNotificationSetting[]>>> {
    const url = `https://${host}/customers/notificationSettings`
    return httpService.patch<
      GenericApiResult<CustomerNotificationSetting[]>,
      CustomerNotificationSettingUpdatePayload
    >(url, payload)
  },
  editCustomer(
    customerId: number,
    payload: EditCustomerPayload
  ): Promise<AxiosResponse> {
    const url = `https://${host}/customers/${customerId}`
    return httpService.patch<ApiResult, EditCustomerPayload>(url, payload)
  },
  uninviteCustomer(customerId: number): Promise<AxiosResponse<ApiResult>> {
    const url = `https://${host}/customerAccounts/${customerId}/invite`
    return httpService.delete(url)
  },
  getRequiredInfo(): Promise<AxiosResponse<RequiredCustomerInfoDTO>> {
    const url = `https://${host}/customers/info/required`
    return httpService.get<RequiredCustomerInfoDTO, void>(url)
  },
  updateRequiredInfo(payload: RequiredCustomerInfoUpdateDTO): Promise<AxiosResponse<RequiredCustomerInfoDTO>> {
    const url = `https://${host}/customers/info/required`
    return httpService.patch<RequiredCustomerInfoDTO, RequiredCustomerInfoUpdateDTO>(url, payload)
  }
}
