import { CustomerValidatePayload } from '@/models/dto'
import { isIncompleteCustomer } from '@/utils/customer'
import auth from '@/services/auth'
import modules from '@/store/modules'
import { Route } from 'vue-router'
import { isEmailSourceInQueryParams } from './url'
import { ViewQuoteSource } from './enum'
import axios from 'axios'
import { StatusCodes } from 'http-status-codes'
import Vue from 'vue'
import { RECEIVE_SMS_QUERY_PARAM, IS_RESERVATION_INVITE_QUERY_PARAM } from '@/utils/constants'

/**
 * A navigation guard function that checks for a permission before allowing navigation.
 * If the permission is granted, it proceeds to the next route.
 * Otherwise, it redirects to a specified route.
 *
 * @param {boolean} hasPermission - A boolean indicating whether the user has the required permission.
 * @param {string = 'home'} redirectName - The name of the route to redirect to if the permission is not granted. Defaults to 'home'.
 * @returns A function that takes the `to`, `from`, and `next` route objects and performs the navigation guard logic.
 */
export const hasPermissionGuard = (hasPermission: boolean, redirectName: string = 'home') => (_to, _from, next) => {
  if (hasPermission) {
    next()
  } else {
    next({ name: redirectName })
  }
}

/**
 * Handles navigation to entity detail pages (reservation or quote) based on hash in the route query.
 * If the user is authenticated and has an incomplete customer profile, redirects to the signup page.
 * If the user is not authenticated, validates the customer and redirects to the signup page if necessary.
 *
 * @param {boolean} isAuthenticated - Indicates if the user is authenticated.
 * @param {Route} to - The target route object.
 * @param {Function} next - The navigation guard function to proceed to the next route.
 * @returns {Promise<void>} - A promise that resolves when the navigation is handled.
 */
export const handleToEntityDetailByHash = async (isAuthenticated: boolean, to: Route, next): Promise<boolean> => {
  const { isValid, entityType, entityHash } = isEntityByHash(to)
  if (!isValid) {
    return false
  }

  if (isAuthenticated) {
    return handleToEntityDetailByHashForAuthenticatedUser(to, entityType, entityHash, next)
  }

  return await handleToEntityDetailByHashForUnauthenticatedUser(to, entityType, entityHash, next)
}


/**
 * Determines if the given route corresponds to an entity identified by a hash.
 *
 * @param to - The route to check.
 * @returns An object containing:
 * - `isValid`: A boolean indicating if the route corresponds to a valid entity.
 * - `entityType` (optional): The type of the entity ('reservation' or 'quote') if valid.
 * - `entityHash` (optional): The hash of the entity if valid.
 */
export const isEntityByHash = (to: Route): { isValid: boolean; entityType?: string; entityHash?: string } => {
  const { name, query } = to
  if (!query) {
    return { isValid: false }
  }

  const isToReservationByHash = name === 'reservation-index' && query.reservationHash
  const isToQuoteByHash = name === 'quote-index' && query.quoteHash

  if (isToReservationByHash) {
    return { isValid: true, entityType: 'reservation', entityHash: query.reservationHash as string }
  }
  if (isToQuoteByHash) {
    return { isValid: true, entityType: 'quote', entityHash: query.quoteHash as string }
  }

  return { isValid: false }
}

/**
 * Handles navigation for authenticated users to entity detail pages (reservation or quote).
 * Redirects to the signup page if the customer profile is incomplete, or proceeds to the entity detail page.
 *
 * @param {Route} to - The target route object
 * @param {string} entityType - The type of the entity ('reservation' or 'quote')
 * @param {string} entityHash - The hash value identifying the entity
 * @param {Function} next - The navigation guard function to proceed to the next route
 * @returns {boolean} - Returns `true` if the navigation is handled, otherwise `false`
 */
const handleToEntityDetailByHashForAuthenticatedUser = (
  to: Route,
  entityType: string,
  entityHash: string,
  next: Function
): boolean => {
  const customer = modules?.auth?.customer

  // TODO: isIncompleteCustomer will be obsolete once Auth0 is live for all.
  // We will be collecting that information on the pop up in the app instead so we can
  // eliminate this code path.

  // Redirect to the signup page if the customer profile is incomplete and Auth0 is not initialized.
  const isAuth0Initialized = Vue.prototype.$auth0.isInitialized
  if (!isAuth0Initialized && isIncompleteCustomer(customer)) {
    next({
      name: 'signup',
      params: {
        firstName: customer?.firstName,
        lastName: customer?.lastName,
        email: customer?.email,
        phone: customer?.phone,
      },
      query: {
        redirectFrom: to.fullPath,
        reservationHash: entityHash,
        isCustomerInvite: 'true',
        isIncompleteCustomer: 'true',
        entityType,
      },
    })
    return true
  }

  const toDetail = {
    name: `${entityType}-detail-by-hash`,
    params: {
      hash: entityHash as string,
    },
    query: {},
  }

  const fromSignup = to.query.fromSignup
  const receiveSMS = to.query[RECEIVE_SMS_QUERY_PARAM]
  const isReservationInvite = to.query[IS_RESERVATION_INVITE_QUERY_PARAM]

  if (fromSignup) { // TODO: I believe this is a legacy query parameter that is no longer in use and can be removed. - Erik 1/22/25
    toDetail.query['fromSignup'] = fromSignup
  }
  if (isReservationInvite) {
    toDetail.query[IS_RESERVATION_INVITE_QUERY_PARAM] = isReservationInvite
  }
  if (receiveSMS) {
    toDetail.query[RECEIVE_SMS_QUERY_PARAM] = receiveSMS
  }

  next(toDetail)
  return true
}

/**
 * Handles navigation for unauthenticated users to entity detail pages (reservation or quote).
 * Validates the customer using the provided hash and redirects to the signup page if necessary.
 *
 * @param {Route} to - The target route object containing navigation details
 * @param {string} entityType - The type of the entity ('reservation' or 'quote')
 * @param {string} entityHash - The hash value identifying the entity
 * @param {Function} next - The navigation guard function to proceed to the next route
 * @returns {Promise<boolean>} - Resolves to `true` if the navigation is handled, otherwise `false`
 */
const handleToEntityDetailByHashForUnauthenticatedUser = async (
  to: Route,
  entityType: string,
  entityHash: string,
  next: Function
): Promise<boolean> => {
  const isQuote = entityType === 'quote'
  const isReservation = entityType === 'reservation'
  const { fullPath: redirectFrom, query: { userHash = '', isCustomerInvite = null } = {} } = to

  const payload: CustomerValidatePayload = {
    quoteHash: isQuote ? entityHash : undefined,
    reservationHash: isReservation ? entityHash : undefined,
    userHash: (userHash as string) || '',
    verifyCustomerDetails: true
  }

  try {
    const { data } = await auth.validateCustomer(payload)
    if (!data) {
      return false
    }

    const { firstName, email, hash } = data
    const isUserCurrentlySigningUp = !!email
    if (!isUserCurrentlySigningUp) {
      return false
    }

    if (isUserCurrentlySigningUp) {
      const query: Record<string, string | string[]> = {
        redirectFrom,
        userHash: userHash || hash,
        isCustomerInvite,
        entityType
      }
      if (isReservation) {
        query.reservationHash = entityHash
      }
      if (isQuote) {
        query.quoteHash = entityHash
      }

      next({
        name: 'signup',
        params: { firstName, email, hash },
        query
      })
      return true
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const { response } = error
      const { status, data } = response
      if (status === StatusCodes.BAD_REQUEST) {
        // 400s are expected to happen here if
        //  - the link has already been used
        //  - the user should be authenticated and isn't
        // no need to log at the error level
        console.warn(data.message)
        return false
      }
    }
    console.error(error)
    return false
  }
}


/**
 * Handles JWT login by checking if the route query contains a JWT token.
 * If a JWT token is found, it attempts to log in using the token.
 *
 * @param {Route} to - The route object containing the query parameters.
 * @returns {Promise<void>} A promise that resolves when the login process is complete.
 */
export const handleDelegatedJWTLogin = async (to: Route): Promise<void> => {
  if (!to.query.jwt) {
    return
  }
  await modules.auth.delegatedJwtLogin(to.query.jwt as string)
}

/**
 * Checks if certain query parameters are present, indicating that the user should be redirected
 * to the quote table view (`quote-index`) with a modal open, instead of viewing the quote by hash.
 * @param {Route} to - The target route.
 * @param {Function} next - The navigation function to continue or redirect.
 * @returns {boolean} - Returns true if redirection to quote table view occurred, otherwise false.
 */
export const redirectToQuoteTableIfNeeded = (to, next) => {
  if (!to.query.quoteHash) return false

  const urlParams = new URLSearchParams(to.fullPath)

  // Check if 'fromEmail' query parameter is set to 'true' to set the quote source.
  if (isEmailSourceInQueryParams(urlParams)) {
    modules.ga4.setViewQuoteSource(ViewQuoteSource.Email)
  }

  // Define parameters that indicate the need for a modal in the table view instead of direct hash navigation.
  const modalTriggerParams = [
    'isLastMinuteTrip',
    'isLongTermShuttle',
    'isLargeEvent',
    'isBillAfterServicesAndWithin4Days'
  ]

  // Collect any modal trigger parameters that are set to 'true'.
  const query = Object.fromEntries(
    modalTriggerParams
      .filter(param => urlParams.get(param) === 'true')
      .map(param => [param, 'true'])
  )

  // Redirect to quote table view with the modal if any trigger parameters are present.
  if (Object.keys(query).length) {
    next({ name: 'quote-index', query })
    return true
  }

  return false
}

/**
 * Checks if a route requires authentication based on its metadata.
 *
 * @param {Route} route - The route object to check.
 * @returns {boolean} True if the route requires authentication; otherwise, false.
 */
export const doesRouteRequireAuth = (route: Route): boolean => {
  const { matched } = route
  if (!matched?.length) {
    return false
  }
  const matchedRoute = matched[matched.length - 1]
  const { meta } = matchedRoute
  return !!meta?.requiresAuth
}
