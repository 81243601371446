
import { phoneFormatFilter } from '@/utils/phone'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { EventBus } from '@/utils/eventBus'
import auth from '../store/modules/auth'
import customer from '@/services/customer'
import reservation from '@/services/reservation'
import { Customer } from '../models/dto'

@Component
export default class PhoneOTCVerificationDialog extends Vue {
  @Prop({ type: Boolean }) readonly value: boolean
  @Prop({ type: String, required: true }) readonly phone: string
  @Prop({ type: Number, required: false }) readonly customerId: number
  @Prop({ type: String, required: false }) readonly reservationHash: string
  @Prop({ type: Boolean, required: false }) readonly anonymous: boolean
  @Prop({ type: Boolean }) readonly skippable: boolean
  @Prop({ type: Boolean }) readonly closeable: boolean

  mfaCode = null
  errorMessage = ''
  submitting = false
  shake = false
  loadingRetry = false
  verified = false

  get formattedPhone(): string {
    if (!this.phone) {
      return ''
    }
    return phoneFormatFilter(this.phone)
  }

  get disabled(): boolean {
    if (!this.mfaCode || this.submitting) {
      return true
    }
    return this.mfaCode.toString().length !== 6
  }

  initiateRetry(): void {
    this.loadingRetry = true
    this.$emit('retry')
    // wait half a second to allow the parent to handle the event
    setTimeout(() => {
      this.loadingRetry = false
    }, 500)
  }

  async submit(): Promise<void> {
    this.submitting = true
    try {
      if (this.anonymous) {
        await reservation.addReservationWatcher(this.reservationHash, {
          phoneNumber: this.phone,
          smsOtp: this.mfaCode.toString()
        })
      } else {
        await customer.verifySMS(this.customerId, {
          phoneNumber: this.phone,
          code: this.mfaCode.toString(),
        })
        await auth.refreshCustomer()
      }
      setTimeout(() => {
        this.$emit('confirm')
      }, 1500)
      this.verified = true
      EventBus.$emit('refresh-contacts')
    } catch (err) {
      this.errorMessage =
        'Could not validate phone number. Please make sure your code is correct and try again.'
      console.error(err)
      this.shake = true
      setTimeout(() => {
        this.shake = false
      }, 1500)
    }
    this.submitting = false
  }
}
