
import { Vue, Component, Prop } from 'vue-property-decorator'
import { CheckoutDetailResult,  Quote } from '@/models/dto'
import {
  currencyFilter,
  twoDigitCentsFromCurrencyAmount,
} from '../utils/string'
import { PaymentTypeKey } from "@/utils/enum"

@Component({})
export default class CheckoutQuoteSummaryDue extends Vue {
  @Prop({ type: Object, required: true })
  readonly quote!: Quote
  @Prop({ type: Object, required: true })
  readonly checkoutDetail!: CheckoutDetailResult

  get dueDate(): string {
    return this.quote?.trips?.[0]?.dueDate
  }

  get dueOnString() {
    if (this.checkoutDetail?.paymentTypeKey === PaymentTypeKey.BillAfterServices) {
      return this.checkoutDetail?.paymentTermDays
        ? `Due ${this.checkoutDetail?.paymentTermDays} Days from Invoice Date`
        : 'Due Later'
    }

    if (this.checkoutDetail?.paymentTermDays) {
      return this.formattedDueDate ? `Due on ${this.formattedDueDate} (Net ${this.checkoutDetail?.paymentTermDays}) ` : 'Due Later'
    }

    return this.formattedDueDate ? `Due on ${this.formattedDueDate}` : 'Due Later'
  }

  get dueNow(): number {
    return this.checkoutDetail.amountDueNow
  }

  get dueNowDollars(): string {
    return `${currencyFilter(this.dueNow).split('.')[0]}`
  }

  get dueNowCents(): string {
    return twoDigitCentsFromCurrencyAmount(this.dueNow)
  }

  get dueLater(): string {
    return currencyFilter(this.checkoutDetail.amountDueEventually)
  }

  get hasAmountDueLater(): boolean {
    return !!this.checkoutDetail?.amountDueEventually
  }

  get formattedDueDate(): string {
    const formattedDate = this.$dayjs(this.dueDate).format('MMM D, YYYY')
    return formattedDate || ''
  }
}
