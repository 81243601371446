var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:{
    'cursor-pointer': _vm.clickable,
    'max-w-580': _vm.stacked,
    'w-400': _vm.carousel,
  },attrs:{"id":`${_vm.id}-bid-card-${_vm.bid.companyId}`},on:{"click":_vm.onClick}},[(_vm.bid)?_c('div',{staticClass:"background-transparent border-solid z-1 h-full border-radius-8",class:_vm.borderClasses},[_c('div',{staticClass:"d-flex",class:{ 'flex-column': _vm.stacked }},[_c('div',{staticClass:"d-flex flex-column padding-a-0 overflow-hidden background-border-gray",class:{ 'col-12': _vm.stacked, 'col-4': !_vm.stacked },style:({
          'border-radius': !_vm.stacked ? '4px 0 0 4px' : '4px 4px 0 0',
          'width': _vm.stacked ? '100%' : '33.3333%',
          'max-width': _vm.stacked ? '100%' : '33.3333%',
        })},[_c('div',{staticClass:"position-relative"},[_c('v-img',{staticClass:"object-fit-cover margin-a-0 z-0 border-radius-top-left-4 border-radius-bottom-right-0",class:{
              'border-radius-top-right-4 border-radius-bottom-left-0': _vm.stacked,
              'border-radius-top-right-0 border-radius-bottom-left-4': !_vm.stacked,
              'opacity-50': !_vm.isValidBid,
            },attrs:{"src":_vm.companyPhotoPath || _vm.fallBackImage,"aspect-ratio":_vm.stacked ? _vm.aspectRatio : null,"height":_vm.companyPhotoHeight}}),(_vm.bid.preferred)?_c('BidCardCertifiedPartnerBadge',{attrs:{"carousel":_vm.carousel}}):_vm._e()],1)]),_c('div',{staticClass:"d-flex flex-grow-1 position-relative",style:({ 'width': _vm.stacked ? '100%' : '66%' })},[_c('div',{staticClass:"d-flex justify-space-between",class:{
                    'padding-t-3': !_vm.stacked,
                    'padding-t-2': _vm.stacked,
                  }},[_c('div',{staticClass:"d-flex flex-column w-full",class:{
              'margin-l-5 padding-r-2': !_vm.stacked,
              'margin-l-3': _vm.stacked,
              'margin-t-1': _vm.carousel,
              'justify-space-between': _vm.flipRatingAndAmenities,
            }},[_c('div',{staticClass:"d-flex align-center"},[(_vm.shouldDisplayCompanyLogo)?_c('div',{staticClass:"w-160"},[_c('v-img',{staticClass:"display-block margin-a-0 z-0 w-full h-auto",attrs:{"src":_vm.companyLogo}})],1):_c('h2',{staticClass:"overflow-hidden text-overflow-ellipsis",class:{
                  'font-16': _vm.stacked,
                  'font-18': !_vm.stacked,
                  'opacity-50': !_vm.isValidBid,
                }},[_vm._v(" "+_vm._s(_vm.bid.companyDBAName)+" ")]),(_vm.isReseller)?_c('ResellerTooltip',{attrs:{"dba":_vm.bid.companyDBAName}}):_vm._e()],1),(_vm.isValidBid || !_vm.stacked)?_c('div',{staticClass:"d-flex flex-column",class:{'flex-column-reverse': _vm.flipRatingAndAmenities}},[_c('CharterUPRating',{staticClass:"d-inline-flex margin-t-1 margin-b-2",staticStyle:{"max-width":"fit-content"},attrs:{"rating":_vm.bid.rating}}),(_vm.showAmenities || _vm.featured)?_c('BidCardAmenities',{attrs:{"bid":_vm.bid,"is-bronze":_vm.isBronze}}):_vm._e()],1):_vm._e()])]),_c('v-spacer'),_c('div',{staticClass:"d-flex margin-t-0 flex-column justify-space-between",class:{
            'padding-y-3 padding-l-4': !_vm.stacked,
            'padding-y-2 border-none': _vm.stacked,
            'border-primary': !_vm.stacked && _vm.featured,
            'border-border-gray': !_vm.stacked && !_vm.featured,
          },style:({
            'border-left': _vm.dottedBorderStyle
          })},[_c('BidCardPricing',{attrs:{"bid":_vm.bid,"stacked":_vm.stacked,"isValidBid":_vm.isValidBid,"charCountMoreThan7":_vm.priceCharCountMoreThan7,"showUsd":_vm.showUsd}}),(_vm.isValidBid)?_c('div',[(!_vm.stacked)?_c('v-btn',{staticClass:"w-140 margin-r-4",attrs:{"id":`${_vm.id}-bid-card-${_vm.bid.companyId}`,"to":_vm.goTo,"disabled":!_vm.clickable,"color":"primary","outlined":_vm.isSecondaryButtonStyle,"small":""}},[_vm._v(" "+_vm._s(_vm.buttonText)+" ")]):_vm._e()],1):_c('BidCardSoldOut',{attrs:{"stacked":_vm.stacked}})],1)],1)])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }