var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"bid-header font-24"},[_vm._v(" "+_vm._s(_vm.$t('quoteDetail.bidList.UNAVAILABLE_COMPANIES'))+" ")]),_c('v-row',_vm._l((_vm.bids),function(bid,bidIndex){return _c('v-col',{key:`${bid.companyId}-${bidIndex}`,attrs:{"cols":"12"}},[_c('BidCard',{attrs:{"id":`provider-bidcard-${bidIndex}`,"preferred":"","go-to":{
          name: 'provider-detail',
          params: {
            quoteId: _vm.quoteId,
            providerId: bid.companyId,
          },
        },"bid":bid,"is-elite":_vm.isElite,"is-branded-quote-flow-enabled":_vm.isBrandedQuoteFlowEnabled}})],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }