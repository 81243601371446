var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"h-full omnibus"},[(_vm.quote)?_c('StickyColumnLayoutV2',{staticClass:"text",attrs:{"max-width":"1052px","background-color":_vm.isSingleColumn ? 'midnight-gray-snow' : 'midnight-gray-white',"single-column":_vm.isSingleColumn,"full-width":_vm.fullWidth},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('CheckoutHeader',{attrs:{"full-width":_vm.fullWidth,"is-single-column":_vm.isSingleColumn,"quote":_vm.quote,"customer-account":_vm.customerAccount,"effective-default-value-set":_vm.effectiveDefaultValueSet}})]},proxy:true},{key:"left-column",fn:function(){return [_c('div',{staticClass:"d-flex flex-column"},[_c('CheckoutPaymentInformation',{ref:"payment-information",staticClass:"margin-b-6",class:{
            'order-1': _vm.hasStoredPaymentProfiles,
            'order-0': !_vm.hasStoredPaymentProfiles,
          },style:({
            'box-shadow': !_vm.fullWidth
              ? '0px 8px 16px 0px rgba(22, 22, 29, 0.08), 0px 4px 48px 0px rgba(22, 22, 29, 0.12)'
              : '',
          }),attrs:{"disputed-checkout":_vm.disputedCheckout,"quote-id":_vm.quoteId,"allowed-payment-methods":_vm.allowedPaymentMethods,"full-width":_vm.fullWidth,"checkout-payment-methods":_vm.checkoutPaymentMethods,"payment-method-types":_vm.paymentMethodTypes},on:{"input":function($event){_vm.formattedPaymentMethodData = $event},"update:payment-profile":function($event){_vm.paymentProfile = $event}}}),(_vm.isSingleColumn)?_c('div',{class:{
            'order-0': _vm.hasStoredPaymentProfiles,
            'order-1': !_vm.hasStoredPaymentProfiles,
          }},[(_vm.isTripSplit)?_c('CheckoutTripSplitNotice',{staticClass:"margin-b-6"}):_vm._e(),_vm._l((_vm.quote.trips),function(trip,tripIndex){return _c('CheckoutTripDetailsAndItinerary',{key:`trip-details-and-itinerary-${trip.tripId}-${tripIndex}`,staticClass:"margin-b-6",attrs:{"hide-expand":_vm.quote.trips.length === 1,"full-width":_vm.fullWidth,"trip":trip},on:{"to-self-serve":_vm.toSelfServe}})})],2):_vm._e(),(_vm.isSingleColumn)?_c('ReservationCancellationPolicy',{staticClass:"margin-b-6 order-3",attrs:{"start-date":_vm.earliestStartDate,"full-width":_vm.fullWidth,"refund-policy":_vm.refundPolicy,"refund-policy-percent":_vm.refundPolicyPercent,"refund-policy-percent-valid-until-time":_vm.refundPolicyPercentValidUntilTime,"is-elite":_vm.isElite,"is-self-serve":_vm.isSelfServe}}):_vm._e(),(_vm.isSingleColumn)?_c('CheckoutQuoteSummary',{staticClass:"margin-b-6 order-4",attrs:{"quote":_vm.quote,"full-width":_vm.fullWidth,"checkout-detail":_vm.checkoutDetail}}):_vm._e(),_c('CheckoutSubmitButton',{ref:"checkoutSubmitButton",staticClass:"margin-b-4 order-5",class:{
            'order-5': !_vm.disputedCheckout,
            'order-6': _vm.disputedCheckout,
          },attrs:{"loading":_vm.isSubmitting,"text":_vm.buttonText,"error":_vm.paymentServiceError},on:{"click":_vm.submit}}),_c('CheckoutTerms',{staticClass:"margin-b-6",class:{
            'order-6': !_vm.disputedCheckout,
            'order-5': _vm.disputedCheckout,
          }}),_c('CheckoutBookingProtectionGuarantee',{staticClass:"margin-b-6 order-7",attrs:{"is-self-serve":_vm.isSelfServe,"full-width":_vm.fullWidth}}),_c('CheckoutValuePropositions',{staticClass:"order-8"}),(_vm.isSingleColumn)?_c('CheckoutSummaryOverlay',{staticClass:"position-fixed bottom-0 left-0 right-0",style:({ 'z-index': _vm.showActionOverlay ? 25 : -5 }),attrs:{"quote":_vm.quote,"checkout-detail":_vm.checkoutDetail},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('CheckoutSubmitButton',{ref:"checkoutOverlaySubmitButton",staticClass:"margin-t-4",attrs:{"loading":_vm.isSubmitting,"text":_vm.buttonText,"error":_vm.paymentServiceError},on:{"click":_vm.submit}})]},proxy:true}],null,false,2700919789)}):_vm._e()],1)]},proxy:true},{key:"right-column",fn:function(){return [_c('CheckoutQuoteSummary',{staticClass:"margin-b-6",attrs:{"quote":_vm.quote,"checkout-detail":_vm.checkoutDetail}}),_c('ReservationCancellationPolicy',{staticClass:"margin-b-6",attrs:{"start-date":_vm.earliestStartDate,"refund-policy":_vm.refundPolicy,"refund-policy-percent":_vm.refundPolicyPercent,"refund-policy-percent-valid-until-time":_vm.refundPolicyPercentValidUntilTime,"is-elite":_vm.isElite,"is-self-serve":_vm.isSelfServe}}),(_vm.isTripSplit)?_c('CheckoutTripSplitNotice',{staticClass:"margin-b-6"}):_vm._e(),_vm._l((_vm.quote.trips),function(trip,tripIndex){return _c('CheckoutTripDetailsAndItinerary',{key:`trip-details-and-itinerary-${trip.tripId}-${tripIndex}`,staticClass:"margin-b-6",attrs:{"hide-expand":_vm.quote.trips.length === 1,"trip":trip,"is-self-serve":_vm.quote.isSelfServe},on:{"to-self-serve":_vm.toSelfServe}})})]},proxy:true}],null,false,2845095147)}):_vm._e(),(_vm.isSelfServe)?_c('Chat',{attrs:{"quote-id":_vm.quoteId}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }