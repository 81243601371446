
import { Vue, Component, Prop } from 'vue-property-decorator'
import { DateTime } from 'luxon'
import { currencyFilter } from '@/utils/string'
import {
  Trip,
  AmenityType,
  CheckoutDetailResult,
  CheckoutDetailQuote,
} from '@/models/dto'
import BookingProtectionDialog from '@/components/BookingProtectionDialog.vue'
import { AmenityTypeId, ChargeTypeId, PaymentTypeKey } from "@/utils/enum"
import { TranslateResult } from 'vue-i18n'

@Component({ components: { BookingProtectionDialog } })
export default class BillingSummary extends Vue {
  @Prop({ type: Boolean }) readonly alreadyBooked: boolean
  @Prop({ type: Object, default: () => {} }) readonly checkoutDetail: CheckoutDetailResult
  @Prop({ type: Boolean }) readonly button: boolean
  @Prop({ type: Boolean, default: false }) readonly isElite: boolean

  currencyFilter = currencyFilter
  addedAmenities = [
    { id: AmenityTypeId.Wifi, label: 'Wifi' },
    { id: AmenityTypeId.LeatherSeats, label: 'Leather seats' },
    { id: AmenityTypeId.AlcoholConsumption, label: 'Alcohol allowed' },
  ]

  get formattedTotal(): string {
    return currencyFilter(this.checkoutDetail?.totalAmount)
  }

  get formattedAmountDueEventually(): string {
    const {amountDueEventually} = this.checkoutDetail || {}
    if (!amountDueEventually) {
      return ''
    }
    return currencyFilter(amountDueEventually)
  }

  get formattedAmountDueNow(): string {
    return currencyFilter(this.checkoutDetail?.amountDueNow)
  }

  get amountDueNow(): number {
    return this.checkoutDetail?.amountDueNow
  }

  get baseFare(): number {
    return this.checkoutDetail.charges.find(
      ({ chargeType }) => chargeType.id === ChargeTypeId.BaseFare
    )?.amount
  }

  get processingFeeAmount(): number {
    return this.checkoutDetail.charges.find(
      ({ chargeType }) => chargeType.id === ChargeTypeId.ProcessingFee
    )?.amount
  }

  get discountAmount(): number {
    return this.checkoutDetail.charges.find(
      ({ chargeType }) => chargeType.id === ChargeTypeId.Discount
    )?.amount
  }

  get totalAmenityAmount(): number {
    return this.checkoutDetail.charges.find(
      ({ chargeType }) => chargeType.id === ChargeTypeId.Amenities
    )?.amount
  }

  get charges(): { label: TranslateResult; amount: number; notes?: string }[] {
    const charges: {
      label: TranslateResult
      amount: number
      notes?: string
    }[] = [
      {
        label: this.$t('quoteDetail.billingSummary.BASE_FARE'),
        notes: this.formattedVehiclesList,
        amount: this.baseFare,
      },
    ]
    if (this.totalAmenityAmount) {
      charges.push({
        label: this.amenityLabel,
        amount: this.totalAmenityAmount,
      })
    }
    if (this.processingFeeAmount) {
      charges.push({
        label: this.$t('quoteDetail.billingSummary.PROCESSING_FEE'),
        amount: this.processingFeeAmount,
      })
    }
    if (this.discountAmount) {
      charges.push({
        label: `${this.$t('quoteDetail.billingSummary.DISCOUNT')} ${
          this.formattedDiscountPercent
        }`,
        amount: this.discountAmount,
      })
    }
    return charges.filter((charge) => charge.amount !== 0)
  }

  get formattedVehiclesList(): string {
    return this.checkoutDetail.quote.trips[0]?.vehicles
      ?.map(({ quantity, vehicleType }) => `${quantity}x ${vehicleType?.label}`)
      .join(', ')
  }

  get formattedDiscountPercent(): string {
    return this.checkoutDetail.discountPercent
      ? `(${Math.abs(this.checkoutDetail.discountPercent)}%)`
      : ''
  }

  get quote(): CheckoutDetailQuote {
    return this.checkoutDetail?.quote
  }

  get trips(): Trip[] {
    return this.quote?.trips || []
  }

  get firstTrip(): Trip {
    return this.trips?.[0]
  }

  get amenities(): AmenityType[] {
    return this.firstTrip?.tripAmenities || null
  }

  get showUsd(): boolean {
    return this.firstTrip?.stops?.[0]?.address?.country === 'CA'
  }

  get amenityLabel(): string {
    const matchedLabels = this.addedAmenities
      .filter((addedAmenity) =>
        this.amenities?.some((amenity) => amenity.id === addedAmenity.id)
      )
      .map((addedAmenity) => addedAmenity.label)
      .join(', ')

    return matchedLabels
  }

  get quoteId(): number {
    return this.quote?.quoteId
  }

  get dueDate(): string {
    const then = DateTime.fromISO(
      this.quote?.trips?.[0]?.dueDate
    ).toLocaleString({ month: 'long', day: 'numeric', year: 'numeric' })
    return then || ''
  }

  get dueOnString() {
    if (this.checkoutDetail?.paymentTypeKey === PaymentTypeKey.BillAfterServices) {
      return this.checkoutDetail?.paymentTermDays
        ? `Due ${this.checkoutDetail?.paymentTermDays} Days from Invoice Date`
        : 'Due Later'
    }

    if (this.checkoutDetail?.paymentTermDays) {
      return this.dueDate ? `Due on ${this.dueDate} (Net ${this.checkoutDetail?.paymentTermDays}) ` : 'Due Later'
    }

    return this.dueDate ? `Due on ${this.dueDate}` : 'Due Later'
  }

  get isSelfServe(): boolean {
    return !!this.quote?.isSelfServe
  }

  get startDate(): string {
    return this.quote?.trips?.[0]?.startDate
  }

  isDiscountChargeType(chargeAmount: number): boolean {
    return chargeAmount === this.discountAmount
  }
}
