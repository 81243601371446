
import { Vue, Component } from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'
import BusJoinUsColorIcon from '@/icons/BusJoinUsColorIcon.vue'
import ProgressBlueColorIcon from '@/icons/ProgressBlueColorIcon.vue'
import MarkerColorIcon from '@/icons/MarkerColorIcon.vue'
import CashColorIcon from '@/icons/CashColorIcon.vue'
import PhoneColorIcon from '@/icons/PhoneColorIcon.vue'

@Component({ components: { ProgressBlueColorIcon, CashColorIcon, PhoneColorIcon, MarkerColorIcon, BusJoinUsColorIcon } })
export default class SignUpSidebar extends Vue {
  valueProps: { icon: any; text: TranslateResult }[] = [
    { icon: ProgressBlueColorIcon, text: this.$t('signup.VALUE_PROP.PRICE') },
    { icon: CashColorIcon, text: this.$t('signup.VALUE_PROP.CANCELLATION') },
    { icon: PhoneColorIcon, text: this.$t('signup.VALUE_PROP.SUPPORT') },
    { icon: MarkerColorIcon, text: this.$t('signup.VALUE_PROP.TRACKING') },
  ]
}
