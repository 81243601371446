
import { Vue, Component } from 'vue-property-decorator'
import selfServe from '@/store/modules/selfServe'

@Component({})
export default class SelfServeQuoteSalesSMSOptIn extends Vue {
  selfServe = selfServe
  get isQuoteSalesSMSEnabled(): boolean {
    return selfServe.quote?.customer?.isQuoteSalesSMSEnabled
  }
}
