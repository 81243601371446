
import { Vue, Component } from 'vue-property-decorator'
import { phoneRaw } from '@/utils/phone'
import user from '@/store/modules/user'
import QuoteSalesSMSOptIn from '@/components/QuoteSalesSMSOptIn.vue'
import ReservationUpdateSMSOptIn from '@/components/ReservationUpdateSMSOptIn.vue'
import auth from '@/store/modules/auth'
import axios from 'axios'
import { IS_RESERVATION_INVITE_QUERY_PARAM, RECEIVE_SMS_QUERY_PARAM } from '@/utils/constants'
import customer from '@/services/customer'
import { MfaSmsPayload } from '@/models/dto/MfaSms'

@Component({ components: { QuoteSalesSMSOptIn, ReservationUpdateSMSOptIn } })
export default class CustomerRequiredDetailsForm extends Vue {
  user = user
  isReservationSMSEnabled = false
  isSubmitting = false
  errorMessage: string | null = null

  async mounted(): Promise<void> {
    await user.prepopulateFromIdToken()
    this.isReservationSMSEnabled = this.showReservationSMSOptIn
  }

  get phoneRaw(): string {
    return user?.phone ? phoneRaw(user.phone) : ''
  }

  get showReservationSMSOptIn(): boolean {
    const isFromReservationInvite = this.$route.query?.[IS_RESERVATION_INVITE_QUERY_PARAM] === 'true'
    return !!isFromReservationInvite
  }

  async submit(): Promise<void> {
    const form = this.$refs['required-customer-info-form'] as any
    const isFormValid = form.validate()
    if (!isFormValid) {
      return
    }

    this.isSubmitting = true
    this.errorMessage = null
    await this.createOrUpdateCustomer()
    if (!!this.errorMessage) {
      return
    }
    await this.login()
    if (!!this.errorMessage) {
      return
    }
    if (this.$route.name !== 'self-serve') { // this triggers a view rerender and we don't want that in self-serve until after the quote is created
      await this.refreshUserRequiredInformation()
    }
    this.$emit('update:customer')
    if (this.isReservationSMSEnabled) {
      await this.updateCustomerSMSForVerification()
      this.updateQueryParamsForReservationSMSOptIn()
    }
  }

  async updateCustomerSMSForVerification(): Promise<void> {
    const customerId = auth.userId
    const phoneNumber = this.phoneRaw
    await customer.updateSMS(
      customerId,
      { phoneNumber }
    )
  }

  updateQueryParamsForReservationSMSOptIn() {
      const route = this.$route
      const query = { ...route.query }
      query[RECEIVE_SMS_QUERY_PARAM] = 'true'
      delete query[IS_RESERVATION_INVITE_QUERY_PARAM]
      const newRoute = { ...route, query }
      this.$router.replace(newRoute)
  }

  async createOrUpdateCustomer(): Promise<void> {
    try {
      if (user.isExistingCustomer) {
        await user.updateRequiredInformation()
      } else {
        await user.createWithRequiredInformation()
      }
    } catch (err: unknown) {
      this.isSubmitting = false
      if (axios.isAxiosError(err)) {
        this.errorMessage = err.response?.data?.message || 'An error occurred saving your information'
      } else {
        this.errorMessage = 'An error occurred saving your information'
      }
      console.warn(err)
    }
  }

  async login(): Promise<void> {
    try {
      await auth.jwtLogin()
    } catch (err: unknown) {
      this.errorMessage = 'An error occurred logging in. Please go back to the login page and try again.'
      console.warn(err)
    }
  }

  async refreshUserRequiredInformation(): Promise<void> {
    try {
      await user.fetchRequiredInformation()
    } catch (err: unknown) {
      console.warn(err)
    }
  }
}
