import { AxiosResponse } from 'axios'
import { baseUrl } from '@/utils/env'
import { HttpService } from './common/HttpService'
import {
  ApiResult,
  User,
  UserEmailExistsResult,
  UserResult,
} from '@/models/dto'
import SharedTrip from '@/models/dto/SharedTrip'
import Auth0UserExistsDTO from '@/models/dto/Auth0UserExistsDTO'
const httpService: HttpService = new HttpService()

export default {
  /**
   * Gets a user by their ID.
   * @param userId - The user's ID to use to get the user.
   * @returns A promise that resolves to the result containing the user.
   */
  byId(userId: number): Promise<AxiosResponse<UserResult>> {
    const host = baseUrl()
    const url = `https://${host}/user/${userId}`
    return httpService.get(url)
  },
  /**
   * Uploads a photo for the user with the given user ID.
   * @param userId - The user's ID to use to upload the photo.
   * @param primaryImage - Whether the photo is the primary image for the user.
   * @param payload - The photo to upload.
   * @returns A promise that resolves to the result of uploading the photo.
   */
  uploadPhoto(
    userId: number,
    primaryImage: boolean,
    payload: FormData
  ): Promise<AxiosResponse<ApiResult>> {
    const host = baseUrl()
    const url = `https://${host}/user/${userId}/uploadPhoto?primaryImage=${primaryImage}`
    return httpService.post<ApiResult, FormData>(url, payload)
  },
  /**
   * Checks if the given email is in use by a user.
   * @param email - The email to check.
   * @returns A promise that resolves to the result of the email check.
   */
  checkEmail(email: string): Promise<AxiosResponse<UserEmailExistsResult>> {
    const host = baseUrl()
    const url = `https://${host}/user/checkEmail/${email}`
    return httpService.get(url)
  },
  /**
   * Retrieves the shared trips for the user with the given user ID.
   * @param userId The user's ID to use to retrieve the shared trips.
   * @returns A promise that resolves to the list of shared trips.
   */
  sharedTrips(userId: number): Promise<AxiosResponse<Array<SharedTrip>>> {
    const host = baseUrl()
    const url = `https://${host}/user/${userId}/sharedEntities`
    return httpService.get(url)
  }
}
