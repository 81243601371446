
import {Vue, Component } from 'vue-property-decorator'
import router from '@/router'
import { logger } from '@/utils/logger'
import auth from '@/services/auth'
import modules from '@/store/modules'
import { CustomerValidatePayload, CustomerValidateResult } from '@/models/dto'
import { RawLocation } from 'vue-router'
import { buildRedirectLoginOptions } from '@/utils/auth'
import user from '@/services/user'
import { RedirectLoginOptions } from '@auth0/auth0-spa-js'
import Auth0AppState from '@/models/dto/Auth0AppState'
import { IS_RESERVATION_INVITE_QUERY_PARAM } from '@/utils/constants'

@Component
export default class SignUpFormV2 extends Vue {

  email = ''
  receiveSMSTripUpdatesCheckbox = true
  isValidCustomer = true

  get isReservationInvite(): boolean {
    return this.$route?.query?.entityType === 'reservation'
  }

  get quoteHash(): string | null {
    return this.$route?.query?.quoteHash as string
  }

  get userHash(): string | null {
    return this.$route?.query?.userHash as string
  }

  get receiveSMSTripUpdates(): boolean {
    return this.isReservationInvite && this.receiveSMSTripUpdatesCheckbox
  }

  created(): void {
    const isAuthenticated = !!modules.auth.isTokenSet
    if (isAuthenticated) {
      router.push({ name: 'quote-index' })
    }
  }

  async mounted(): Promise<void> {
    await this.populateUserDetails()
    this.goToAuth0UsernameAuthentication()
  }

  buildCustomerValidationPayload(): CustomerValidatePayload {
    const validateUserPayload: CustomerValidatePayload = { verifyCustomerDetails: true }
      if (this.quoteHash) {
        validateUserPayload.quoteHash = this.quoteHash
      }

      if (this.userHash) {
        validateUserPayload.userHash = this.userHash
      }

      return validateUserPayload
  }

  async validateCustomer(): Promise<CustomerValidateResult> {
    const validateUserPayload = this.buildCustomerValidationPayload()
    try {
      const { data } = await auth.validateCustomer(validateUserPayload)
      return data
    } catch (error) {
      logger.error(error)
      this.isValidCustomer = false
      throw error
    }
  }

  async populateUserDetails(): Promise<void> {
    const { params } = this.$route

    // Route might contain quoteHash or userHash,
    // meaning this is a refresh for a new user signup.
    if (this.quoteHash || this.userHash) {
      try {
        const { email } = await this.validateCustomer()
        this.email = email
      } catch (error) {
        logger.warn('Failed to populate user details:', error)
        return
      }
    } else {
      // Populate details from route params
      const { email = '' } = params
      this.email = email
    }
  }

  getRedirect(): RawLocation {
    const { redirectFrom } = this.$route?.query || {}

    if (!redirectFrom) {
      return { name: 'quote-index' } as RawLocation
    }

    const baseUrl = redirectFrom.toString()
    const hasExistingParams = baseUrl.includes('?')
    const paramPrefix = hasExistingParams ? '&' : '?'

    const signupFromReservationInviteParam = `${IS_RESERVATION_INVITE_QUERY_PARAM}=true`
    const toQuery = `${baseUrl}${paramPrefix}${signupFromReservationInviteParam}` as RawLocation
    return { path: toQuery } as RawLocation

  }

  goToAuth0UsernameAuthentication(): void {
    const isNewCustomer = !!this.userHash
    const redirect = this.getRedirect()
    const appState: Auth0AppState = {}

    if (redirect.name) {
      appState.routeName = redirect.name
    }
    if (redirect.path) {
      appState.redirectFrom = redirect.path
    }

    const options: RedirectLoginOptions = buildRedirectLoginOptions(appState, this.email)

    if (isNewCustomer) {
      this.$auth0.signupWithRedirect(options)
      } else {
      this.$auth0.loginWithRedirect(options)
    }
  }

}
