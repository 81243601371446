
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class ResellerTooltip extends Vue {

  @Prop({ type: String, required: true }) readonly dba: string

  get tooltipText(): string {
    return `${this.dba} is a nationwide network of bus companies that independently operate under the ${this.dba} brand.`
  }
}
