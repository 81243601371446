import { AxiosError } from "axios"
import { StatusCodes } from "http-status-codes"

export const isUnauthorizedStatus = (error: AxiosError): boolean => {
  return error?.response?.status === StatusCodes.UNAUTHORIZED
}

export const isForbiddenStatus = (error: AxiosError): boolean => {
  return error?.response?.status === StatusCodes.FORBIDDEN
}
