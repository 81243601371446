
import { Vue, Component } from 'vue-property-decorator'
import CustomerRequiredDetailsForm from '@/components/CustomerRequiredDetailsForm.vue'
import CharterUPLogo from '@/icons/CharterUPLogo.vue'
import auth from '@/store/modules/auth'

@Component({ components: { CustomerRequiredDetailsForm, CharterUPLogo } })
export default class CustomerRequiredInfoDialog extends Vue {
  get fullscreen(): boolean {
    return this.$vuetify.breakpoint.xsOnly
  }

  logout(): void {
    auth.logout()
  }

}
