import { AuthorizationParams, RedirectLoginOptions } from "@auth0/auth0-spa-js";

/**
 * Builds the options required for redirecting to the login or sign up page.
 *
 * @param {any} appState - The state of the application to use when redirecting.
 * @param {string} email - (optional) The email address of the user to pre-fill the login or sign up form.
 * @returns The options for redirecting to the login or signup page.
 */
export const buildRedirectLoginOptions = (appState: any, email?: string): RedirectLoginOptions => {
  const authorizationParams: AuthorizationParams = {
    redirect_uri: `${window.location.origin}/auth-callback`
  }
  if (email) {
    authorizationParams.login_hint = email
  }
  return {
    appState,
    authorizationParams
  }
}
