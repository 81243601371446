import { Address, Stop, SalesBotStop as SalesBotStopDTO } from '@/models/dto'
import { DateTime } from 'luxon'
export class SalesBotStop implements SalesBotStopDTO {
  active = true
  address = null
  createdOn = null
  dropoffDatetime = null
  isArrived
  isDropoffEstimated = false
  isPickupEstimated = false
  orderIndex = null
  pickupDatetime = null
  startDatetime = null
  tripId = null
  updatedOn = null
  time = ''
  date = ''
  travelTimeFromPreviousStopInSeconds = null
  estimatedDropoffDatetime = null

  constructor(stop?: Partial<Stop>) {
    if (!stop) {
      return
    }
    Object.assign(this, stop)
    this.setDateAndTime()
  }

  private setDateAndTime(): void {
    if (!this.pickupDatetime) {
      return
    }
    const datetime = DateTime.fromISO(this.pickupDatetime, {
      zone: this.address.timeZone,
    })
    this.date = datetime.toISODate()
    this.time = datetime.toISOTime()
  }

  setAddress(address: Address): void {
    this.address = address
  }

  toObject(): Stop {
    const stop = Object.assign({}, this)
    delete stop.time
    delete stop.date
    delete stop.travelTimeFromPreviousStopInSeconds
    delete stop.estimatedDropoffDatetime
    return stop
  }
}
