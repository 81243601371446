
import { Vue, Component, Prop } from 'vue-property-decorator'
import BidCard from '@/components/BidCard.vue'
import { QuoteDetailV2, QuoteDetailV2Bid } from '../models/dto/QuoteDetailV2'
import { InfoModalDetail } from '@/models/InfoModalDetail'
import { InfoModalVariant } from '@/utils/enum'
import InfoModal from './InfoModal.vue'

@Component({ components: { BidCard, InfoModal } })
export default class QuoteDetailBidListCharterUpChoice extends Vue {
  @Prop({ required: true, type: Object }) bid!: QuoteDetailV2Bid
  @Prop({ required: true, type: Boolean }) isElite!: boolean
  @Prop({ type: Object, default: null }) readonly quote: QuoteDetailV2
  @Prop({ type: Boolean, required: true }) isBrandedQuoteFlowEnabled: boolean

  charterupChoiceModalDetail: InfoModalDetail = {
    label: 'CharterUP’s Choice',
    shortLabel: 'CharterUP’s Choice',
    icon: {
      name: 'charterup_choice',
      color: 'primary',
      viewBox: '0 0 24 24',
    },
    modalVariant: InfoModalVariant.Custom,
    showCharterUPLogo: true,
    showSupportButton: false
  }

  isOpen = false

  get operatorName(): string {
    return this.bid.companyDBAName
  }

  get quoteId(): number {
    return this.quote?.quoteId
  }

  get isPickupInCanada(): boolean {
    return this.quote?.trips?.[0]?.stops?.[0]?.address?.country === 'CA'
  }
}
