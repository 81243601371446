
import { Vue, Component } from 'vue-property-decorator'
import auth from '@/store/modules/auth'
import { AuthPayload } from '@/models/dto'
import { RawLocation } from 'vue-router'
import AlertBox from '@/components/AlertBox.vue'

@Component({
  components: { AlertBox },
})
export default class LoginLegacyForm extends Vue {
  valid = false
  error = false
  isSubmitting = false
  passwordHidden = true
  email = ''
  password = ''
  emailRules = [
    (v) => !!v || this.$t('login.EMAIL_REQUIRED'),
    (v) => /.+@.+/.test(v) || this.$t('login.EMAIL_MUST_BE_VALID'),
  ]
  passwordRules = [(v) => !!v || this.$t('login.PASSWORD_IS_REQUIRED')]

  async submit(): Promise<void> {
    if (this.isSubmitting) {
      return
    }
    this.error = false

    const form = this.$refs.form as any
    const formIsValid = await form.validate()
    if (!formIsValid) {
      return
    }
    this.isSubmitting = true
    const payload: AuthPayload = {
      email: this.email,
      password: this.password,
    }

    const success = await auth.login(payload)
    if (!success) {
      this.error = true
      this.isSubmitting = false
      return
    }

    form.reset()
    this.isSubmitting = false
    this.trackLogin()
    this.redirect()
  }

  trackLogin(): void {
    this.$ga4Event('login', undefined)
  }

  redirect(): void {
    const to = (this.$route.query.redirectFrom || {
      name: 'quote-index',
    }) as RawLocation
    this.$router.push(to)
  }

}
