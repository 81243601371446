
import { Vue, Component, Watch } from 'vue-property-decorator'
import TheSidebar from '@/components/TheSidebar.vue'
import TheAppBar from '@/components/TheAppBar.vue'
import TheAlert from '@/components/TheAlert.vue'
import TheFooter from '@/components/TheFooter.vue'
import TheSupportDialog from '@/components/TheSupportDialog.vue'
import Confetti from '@/components/Confetti.vue'
import TheBookingExperienceSurvey from '@/components/TheBookingExperienceSurvey.vue'
import CustomerRequiredInfoDialog from '@/components/CustomerRequiredInfoDialog.vue'
import auth from '@/store/modules/auth'
import app from '@/store/modules/app'
import confetti from '@/store/modules/confetti'
import footer from '@/store/modules/footer'
import support from '@/store/modules/support'
import survey from '@/store/modules/survey'
import alert from '@/store/modules/alert'
import locale from '@/store/modules/locale'
import appMenu from '@/store/modules/appMenu'
import user from '@/store/modules/user'

@Component({
  components: {
    TheSidebar,
    Confetti,
    TheBookingExperienceSurvey,
    TheAppBar,
    TheAlert,
    TheFooter,
    TheSupportDialog,
    CustomerRequiredInfoDialog
  },
  metaInfo() {
    return {
      title: this.appTitle,
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      meta: [
        {
          name: 'msapplication-TileColor',
          content: '#ffc40d',
        },
        {
          name: 'theme-color',
          content: '#ffffff',
        },
        {
          name: 'apple-mobile-web-app-title',
          content: this.appTitle,
        },
        {
          name: 'application-name',
          content: this.appTitle,
        },
      ],
      link: [
        {
          rel: 'manifest',
          href: '/site.webmanifest',
        },
        {
          rel: 'favicon',
          href: '/favicon.ico',
        },
      ],
    }
  },
})
export default class Home extends Vue {
  confetti = confetti
  auth = auth
  support = support
  survey = survey
  alert = alert
  locale = locale
  appMenu = appMenu

  get isAuth0Initialized(): boolean {
    return !!this.$auth0?.isInitialized
  }

  get key(): string {
    const localeKey = `locale-${locale.locale}`
    const isRequiredInformationCompleteKey = `required-info-${user.isRequiredInformationComplete}`
    return `${localeKey}-${isRequiredInformationCompleteKey}`
  }

  get appTitle(): string {
    return app.title
  }

get shouldShowFooter(): boolean {
    const { name: routeName, params } = this.$route || {}
    const hideFooterRoutes = ['live-tracking', 'enterprise-tracking']
    const isConfirmMode = params?.mode === 'confirm'
    return footer.show && !hideFooterRoutes.includes(routeName) && !isConfirmMode
}

  get isAuthenticatedUser(): boolean {
    return auth.isTokenSet
  }

  get isAuthorizedUser(): boolean {
    return this.isAuthenticatedUser && !!auth.userId
  }

  get shouldShowRequiredInfoForm(): boolean {
    if (!this.isAuth0Initialized) {
      return false
    }
    const doesRouteRequireAuth = !!this.$route?.meta?.requiresAuth
    const isRequiredInformationComplete = !!user.isRequiredInformationComplete
    return this.isAuthenticatedUser && doesRouteRequireAuth && !isRequiredInformationComplete
  }
}
