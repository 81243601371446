var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex justify-center"},[_c('v-form',{ref:"self-serve-customer-form",staticClass:"max-w-600 w-full"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('label',[_vm._v("Email")]),_c('v-text-field',{ref:"email",attrs:{"id":"customer-information-email-text-field","value":_vm.email,"placeholder":"e.g. james@gmail.com","rules":[
            (v) => !!v || 'Email is required',
            (v) =>
              /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,13}$/.test(v) ||
              'E-mail must be valid',
          ],"type":"email","autocomplete":"email","hide-details":"auto","required":"","validate-on-blur":"","outlined":""},on:{"input":function($event){_vm.customer.email = $event},"keyup":function($event){return _vm.checkCustomerEmail(false)},"blur":function($event){return _vm.checkCustomerEmail(true)}}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('label',[_vm._v("Create password")]),_c('v-text-field',{ref:"password",attrs:{"id":"customer-password-password-text-field","value":_vm.password,"rules":[
            (v) => !!v || 'Password is required',
            (v) => !this.shouldValidatePassword || this.asyncPasswordResponse.data.valid || this.asyncPasswordResponse.data.message
          ],"append-icon":_vm.passwordHidden ? 'visibility' : 'visibility_off',"type":_vm.passwordHidden ? 'password' : 'text',"hide-details":"auto","autocomplete":"new-password","required":"","validate-on-blur":"","outlined":""},on:{"click:append":() => (_vm.passwordHidden = !_vm.passwordHidden),"input":function($event){_vm.customer.password = $event},"change":_vm.resetPasswordValidation}})],1),(_vm.selfServe.isExistingUser)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"padding-a-6 border-primary background-blue-10 margin-b-2"},[_c('v-row',{staticClass:"align-center"},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('img',{attrs:{"src":require("@/assets/images/signIn.png"),"width":"100%"}})]),_c('v-col',{class:{
                'text-right': _vm.$vuetify.breakpoint.smAndUp,
                'text-center': _vm.$vuetify.breakpoint.xs,
              },attrs:{"cols":"12","sm":"6"}},[_c('h2',{ref:"signIn"},[_vm._v("Welcome back!")]),_c('p',[_vm._v(" It looks like a user with that email already exists. ")]),_c('v-btn',{staticClass:"margin-t-2",attrs:{"color":"primary","small":""},on:{"click":_vm.selfServe.goToSignIn}},[_vm._v(" Sign In ")])],1)],1)],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('label',[_vm._v("First Name")]),_c('v-text-field',{ref:"first-name",attrs:{"id":"customer-information-first-name-text-field","value":_vm.firstName,"placeholder":"e.g. James Joseph","rules":[(v) => !!v || 'First name is required'],"hide-details":"auto","autocomplete":"given-name","required":"","validate-on-blur":"","outlined":""},on:{"input":function($event){_vm.customer.firstName = $event}}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('label',[_vm._v("Last Name")]),_c('v-text-field',{attrs:{"id":"customer-information-last-name-text-field","value":_vm.lastName,"placeholder":"e.g. Smith","rules":[(v) => !!v || 'Last name is required'],"hide-details":"auto","autocomplete":"family-name","required":"","validate-on-blur":"","outlined":""},on:{"input":function($event){_vm.customer.lastName = $event}}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('label',[_vm._v("Phone Number")]),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(['(###) ###-####', '+## ## #### ####']),expression:"['(###) ###-####', '+## ## #### ####']"}],attrs:{"id":"customer-information-phone-text-field","value":_vm.phone,"placeholder":"e.g. (123) 456-7890","rules":[
            (v) => !!v || 'Phone number is required',
            _vm.phoneRaw.length > 9 || 'Phone number must be valid',
            _vm.phoneRaw.length <= 12 || 'Phone number must be valid',
          ],"hide-details":"auto","autocomplete":"tel","required":"","validate-on-blur":"","outlined":""},on:{"input":function($event){_vm.customer.phone = $event}}}),_c('div',{staticClass:"d-flex justify-start margin-l-1"},[_c('span',{staticClass:"font-12 text-gray-subtle-text"},[_vm._v(" In case we need to contact you about your booking. ")])])],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('label',[_vm._v(" How many buses do you book in a year? ")]),_c('v-text-field',{attrs:{"id":"customer-information-bookings-text-field","value":_vm.bookings,"type":"number","placeholder":"e.g. 1","hide-details":"auto","rules":[
            (v) =>
              !v || Number.isInteger(Number(v)) || 'Must be a whole number',
            (v) => !v || parseInt(v) > 0 || 'Must be a positive number',
          ],"outlined":"","step":"1","min":"1","max":"999999"},on:{"input":function($event){_vm.customer.bookings = $event}}}),_c('div',{staticClass:"d-flex justify-start margin-l-1"},[_c('span',{staticClass:"font-12 text-gray-subtle-text"},[_vm._v("Optional")])])],1),_c('SelfServeQuoteSalesSMSOptIn'),_c('v-col',{staticClass:"d-flex justify-center margin-t-6",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"w-full",class:{
            'max-w-320': _vm.$vuetify.breakpoint.smAndUp,
          },attrs:{"color":"primary","disabled":_vm.disabled,"loading":_vm.submitting},on:{"click":_vm.submit}},[_vm._v(" Continue "),_c('CUIcon',{staticClass:"margin-l-2",attrs:{"view-box":"0 0 24 24"}},[_vm._v(" arrow_right ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }