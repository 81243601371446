
import { Vue, Prop, Component, Watch } from 'vue-property-decorator'
import InfoDialogLayout from '@/layouts/InfoDialogLayout.vue'
import auth from '@/store/modules/auth'
import { phoneFormatFilterHyphens, getSupportNumber } from '@/utils/phone'
import CustomerAccountDefaultValueSet from '@/models/dto/CustomerAccountDefaultValueSet'
import AccountExecutive from '@/models/dto/AccountExecutive'
import customerAccount from '@/services/customerAccount'

@Component({ components: { InfoDialogLayout } })
export default class PromptCallForBASDialog extends Vue {
  @Prop({ type: Boolean, required: true }) readonly value: boolean
  effectiveDefaultValueSet: CustomerAccountDefaultValueSet = null

  async created(): Promise<void> {
    await this.getEffectiveDefaultValueSet()
  }

  @Watch('auth.customerAccount.customerAccountId')
  customerAccountChanged(): void {
    this.getEffectiveDefaultValueSet()
  }

  get formattedAccountExecutiveNumber(): string {
    const accountExecutivePhoneNumber = this.accountExecutive?.phone
    const isElite = !!auth.customer?.isElite
    const supportNumber = getSupportNumber(isElite)
    const rawPhone = accountExecutivePhoneNumber || supportNumber
    return phoneFormatFilterHyphens(rawPhone)
  }

  get accountExecutive(): AccountExecutive {
    return this.effectiveDefaultValueSet?.accountExecutive
  }

  quotePromptCallNumber(): void {
    window.open(`tel:${this.formattedAccountExecutiveNumber}`)
  }

  async getEffectiveDefaultValueSet(): Promise<void> {
    if (!auth.user || !auth.customerAccount) {
      return
    }
    try {
      const { data } = await customerAccount.effectiveDefaultValueSet(
        auth.customerAccount.customerAccountId
      )
      this.effectiveDefaultValueSet = data
    } catch (error) {
      console.error('Error fetching effective default value set', error)
    }
  }
}
