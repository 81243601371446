
import { Vue, Component } from 'vue-property-decorator'
import auth from '@/store/modules/auth'
import app from '@/store/modules/app'
import { RawLocation } from 'vue-router'
import LoginLegacyForm from '@/components/LoginLegacyForm.vue'
import { SplitFeatureFlag } from '@/utils/enum'
import { RedirectLoginOptions } from '@auth0/auth0-spa-js'
import { buildRedirectLoginOptions } from '@/utils/auth'

@Component({
  components: { LoginLegacyForm },
  metaInfo() {
    return {
      title: app.title,
      titleTemplate: `${this.$t('login.TITLE')} - %s`,
    }
  },
})
export default class Login extends Vue {
  isAuth0Enabled: boolean = false

  async mounted(): Promise<void> {
    if (auth.isTokenSet) {
      // TODO: move to before enter guard - Erik 12/27/24
      this.redirect()
    }
    this.isAuth0Enabled = await this.checkIsAuth0Enabled()
    if (this.isAuth0Enabled) {
      this.goToAuth0UsernameSignIn()
    }
  }

  async checkIsAuth0Enabled(): Promise<boolean> {
    // Fetch feature flag to determine if Auth0 is enabled
    // only use the queryparam if it is exactly 'true' or 'false'
    // ignore if it is something like ?auth0=t or ?auth0=f
    const auth0QueryParam = this.$route.query.auth0
    if (auth0QueryParam === 'true') {
      await this.$auth0.init()
      return true
    }
    if (auth0QueryParam === 'false') {
      return false
    }
    return this.$split.isFeatureEnabled(SplitFeatureFlag.Auth0SignIn)
  }

  redirect(): void {
    const to = (this.$route.query.redirectFrom || {
      name: 'quote-index',
    }) as RawLocation
    this.$router.push(to)
  }

  goToAuth0UsernameSignIn(email?: string): void {
    if (!this.isAuth0Enabled) {
      return
    }
    const options: RedirectLoginOptions = this.buildRedirectOptions(email)
    this.$auth0.loginWithRedirect(options)
  }

  buildRedirectOptions(email?: string): RedirectLoginOptions {
    const appState = {
      routeName: 'home',
      redirectFrom: this.$route.query.redirectFrom
    }

    const options: RedirectLoginOptions = buildRedirectLoginOptions(appState, email)
    return options
  }

}
