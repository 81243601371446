
import {Vue, Component } from 'vue-property-decorator'
import MainWithSidePanelSignupLayout from '@/layouts/MainWithSidePanelSignupLayout.vue'
import SignUpSidebar from '@/components/SignUpSidebar.vue'
import SignUpFormLegacy from '@/components/SignUpFormLegacy.vue'
import SignUpFormV2 from '@/components/SignUpFormV2.vue'
import app from '@/store/modules/app'
import { SplitFeatureFlag } from '@/utils/enum'

// TODO: Once Auth0 is live for all:
// - Consider removing this page altogether and redirecting to Auth0 signup.
// - Gather additional information after signup in the required info form.
//   - This form already supports all fields except for the trip SMS opt-out.

@Component({
  metaInfo() {
    return {
      title: app.title,
      titleTemplate: `${this.$t('signup.TITLE')} - %s`,
    }
  },
  components: {
    MainWithSidePanelSignupLayout,
    SignUpSidebar,
    SignUpFormLegacy,
    SignUpFormV2
  },
})
export default class SignUp extends Vue {
  isAuth0Enabled = false

  async mounted(): Promise<void> {
    this.isAuth0Enabled = await this.$split.isFeatureEnabled(SplitFeatureFlag.Auth0SignIn) || !!this.$auth0?.isInitialized
  }
}
